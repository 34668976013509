import React, { Component } from 'react';
import { Provider } from 'react-redux';
import store from './lib/redux';
import { Switch, Route, HashRouter as Router, Link } from "react-router-dom";

import AWS from 'aws-sdk';

import AWSContentFetcher from './components/data/AWSContentFetcher';
import SchedulingSection from './components/scheduling/SchedulingSection';
import UsersSection from './components/users/UsersSection';
import GroupsSection from './components/groups/GroupsSection';

import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws-exports'//'./src/aws-exports';
import { Authenticator, withAuthenticator } from 'aws-amplify-react';

import { Button, Container, Grid, Icon, Image, Menu, Segment } from 'semantic-ui-react';

import MyTheme from './amplify-themes/basicTheme';
import 'semantic-ui-less/semantic.less';

import IdentityService from "./components/data/IdentityService";
import SQSMessagingService from "./components/scheduling/SQSMessagingService";
import UserService from './components/data/users/UserService';
import DatabaseService from './components/data/DatabaseService';
import GroupService from './components/data/groups/GroupService';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import EmailService from './components/data/EmailService';
import Inbox from './components/content/Inbox/Inbox';
import ChatSection from './components/content/chat/ChatSection';
import Documents from './components/content/documents/Documents';
import Tasks from './components/content/tasks/Tasks';

import logo from "./images/Web_Logo.png";

//Amplify.configure(awsconfig);
Amplify.configure({
  Auth: {

      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: 'us-east-1:a151fe85-84ba-43e7-9c0e-23e30bb6878e',

      // REQUIRED - Amazon Cognito Region
      region: 'us-east-1',

      // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
      // Required only if it's different from Amazon Cognito Region
      identityPoolRegion: 'us-east-1',

      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'us-east-1_VKndxxrH6',

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: '1enimgplmomcvlmub08mlg2kkt',

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      mandatorySignIn: false,

      // OPTIONAL - Configuration for cookie storage
      // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
      //cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      //    domain: '.callistomission.org',
      // OPTIONAL - Cookie path
      //    path: '/',
      // OPTIONAL - Cookie expiration in days
      //    expires: 365,
      // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
      //    sameSite: "strict" | "lax",
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      //    secure: true
      //},

      // OPTIONAL - customized storage object
      //storage: MyStorage,

      // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
      authenticationFlowType: 'USER_PASSWORD_AUTH',

      // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
      //clientMetadata: { myCustomKey: 'myCustomValue' },

       // OPTIONAL - Hosted UI configuration
      oauth: {
          //domain: 'your_cognito_domain',
          //scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          //redirectSignIn: 'http://localhost:3000/',
          //redirectSignOut: 'http://localhost:3000/',
          //responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
      }
  },
  API: {
    aws_appsync_graphqlEndpoint: "https://inf2rttt2rdghghhrkepug5y74.appsync-api.us-east-1.amazonaws.com/graphql",
    aws_appsync_region: "us-east-1",
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS"
  }
});

class App extends Component {
  constructor(props) {
    super(props);
    
    this.state = { 
      latestContent:  <div></div>,
      latestDocs: <div></div>,
      latestTasks: <div></div>,
      isAdmin:  false
    };
    this.fetcher = new AWSContentFetcher();

    this.fetchEpisodeContent();
    this.fetchDocumentContent();
    this.fetchTaskContent();

    Auth.currentAuthenticatedUser().then(async (result) => {
      
      await this.checkCreateGameForUser(result);
      
      AWS.config.update({
        region: awsconfig["aws_appsync_region"]
      });
      
      let credentials = await Auth.currentCredentials();
      const sqs = new AWS.SQS({credentials: Auth.essentialCredentials(credentials)});
      await SQSMessagingService.setupSQSConsumer(sqs);

      IdentityService.isAdmin().then(isAdminResult => {
        console.log("admin in the house " + isAdminResult)
        this.setState((prevState) => {
          let newState = prevState;
          newState.isAdmin = isAdminResult;
          return newState;
        });
      });
    });
  }

  async checkCreateGameForUser() {
    // Get Game ID for user
    console.log("What is the gameId for this user?");
    let gameId = await UserService.GetAttributeFromCurrentUser("custom:gameId");
    console.log(gameId);

    // If there is a Game ID, we are done. That means this user was added by an Admin
    // Otherwise, we have work to do...
    if (gameId) return;
    console.log("No gameId found.")

    // 1. Create a Game for this user
    let databaseService = new DatabaseService();
    let createGameInput = {
      invitationEmailTemplate: EmailService.GetDefaultInvitationEmailTemplate()
    };
    console.log("creating game");
    let newGame = await databaseService.CreateGame(createGameInput);
    console.log(newGame);
    if (!newGame || !newGame.data || !newGame.data.createGame) return;

    console.log("updating gameId attribute");
    let currentUser = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(currentUser, {
      "custom:gameId" : newGame.data.createGame.id
    });

    // 2. Create 2 Groups: Admins and Ungrouped
    console.log("creating Ungrouped group");
    await GroupService.CreateGroup("Ungrouped");

    // 3. Add this user to the Admin group
    console.log("adding user to group");
    let username = await UserService.GetAttributeFromCurrentUser("email");
    await UserService.AddUserToGroup(username, "Admin");

    // 4. Add this user to the database (making sure to include this new Game ID)
    console.log("adding user to db");
    await UserService.AddUserToDb(username, currentUser.username, "Admin");
  }

  async fetchEpisodeContent() {
    let self = this;
    self.fetcher.fetchEpisodeContent()
    .then(function(result) {
      console.log("content: ");
      console.log(result);
      self.setState({ latestContent: result});
      localStorage.setItem('lastUpdated', new Date());
    });
  }

  async fetchDocumentContent() {
    let self = this;
    self.fetcher.fetchDocumentContent()
    .then(function(result) {
      console.log("content: ");
      console.log(result);
      self.setState({ latestDocs: result});
      localStorage.setItem('lastUpdated', new Date());
    });
  }

  async fetchTaskContent() {
    let self = this;
    self.fetcher.fetchTaskContent()
    .then(function(result) {
      console.log("tasks: ");
      console.log(result);
      self.setState({ latestTasks: result});
      localStorage.setItem('lastUpdated', new Date());
    });
  }

  render() {
    console.log(this.state);
    let schedulingButton = this.state.isAdmin ?
      <Link to="/scheduling">
        <Button className="main-menu" icon labelPosition="left">
          <Icon name="calendar" />
          <span className="menu-button-text">Scheduling</span>
        </Button>
      </Link> : "";

    let schedulingRoute = this.state.isAdmin ?
      <Route path="/scheduling">
        <SchedulingSection />
      </Route> : "";

    let usersButton = this.state.isAdmin ?
    <Link to="/users">
      <Button className="main-menu" icon labelPosition="left">
        <Icon name="user" />
        <span className="menu-button-text">Users</span>
      </Button>
    </Link> : "";

    let usersRoute = this.state.isAdmin ?
    <Route path="/users">
      <UsersSection />
    </Route> : "";

    let documentsButton = <Link to="/documents">
      <Button className="main-menu" icon labelPosition="left">
        <Icon name="folder" />
        <span className="menu-button-text">Documents</span>
      </Button>
    </Link>;

    let documentsRoute =
    <Route path="/documents">
      <Documents content={this.state.latestDocs} />
    </Route>;

    let groupsButton = this.state.isAdmin ?
    <Link to="/groups">
      <Button className="main-menu" icon labelPosition="left">
        <Icon name="group" />
        <span className="menu-button-text">Groups</span>
      </Button>
    </Link> : "";

    let groupsRoute = this.state.isAdmin ?
    <Route path="/groups">
      <GroupsSection />
    </Route> : "";

    let inboxButton = <Link to="/inbox">
      <Button className="main-menu" icon labelPosition="left">
        <Icon name="mail" />
        <span className="menu-button-text">Inbox</span>
      </Button>
    </Link>;

    let tasksButton = <Link to="/tasks">
      <Button className="main-menu" icon labelPosition="left">
        <Icon name="clipboard check" />
        <span className="menu-button-text">Tasks</span>
      </Button>
    </Link>;

    let tasksRoute =
    <Route path="/tasks">
      <Tasks content={this.state.latestTasks} />
    </Route>;

    let chatButton = <Link to="/chat">
      <Button className="main-menu" icon labelPosition="left">
        <Icon name="talk" />
        <span className="menu-button-text">Chat</span>
      </Button>
    </Link>;

    let chatRoute =
    <Route path="/chat">
      <ChatSection />
    </Route>;

    return (
      <Provider store={store}>
          <Authenticator usernameAttributes='email' theme={MyTheme}/>
          <Container fluid>
            <Router>
              <Grid stackable>
                <Grid.Column stretched computer={2} mobile={1} tablet={1}>
                <Menu fluid vertical>
                  <Menu.Item>
                    {usersButton}
                  </Menu.Item>
                  <Menu.Item>
                    {groupsButton}
                  </Menu.Item>
                  <Menu.Item>
                    {schedulingButton}
                  </Menu.Item>
                  <Menu.Item>
                    {inboxButton}
                  </Menu.Item>
                  <Menu.Item>
                    {chatButton}
                  </Menu.Item>
                  <Menu.Item>
                    {documentsButton}
                  </Menu.Item>
                  <Menu.Item>
                    {tasksButton}
                  </Menu.Item>
                </Menu>
                </Grid.Column>
                <Grid.Column stretched width={14}>
                  <Grid.Row>
                    <Segment className="transparent-bg no-border">
                      <Grid>
                        <Grid.Row stretched>
                          <Grid.Column width={14} />
                          <Grid.Column stretched width={2}>
                            <Image src={logo} width={200} className="logo" />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Segment>
                  </Grid.Row>
                  <Grid.Row>
                    <Segment attached="bottom" className="transparent-bg no-border">
                      <Switch>
                        <Route path="/inbox">
                          <Inbox content={this.state.latestContent} />
                        </Route>
                        {usersRoute}
                        {groupsRoute}
                        {schedulingRoute}
                        {documentsRoute}
                        {tasksRoute}
                        {chatRoute}
                      </Switch>
                    </Segment>
                  </Grid.Row>
                </Grid.Column>
              </Grid>
            </Router>
          </Container>
          <ToastContainer />
      </Provider>
    );
  }
}
export default withAuthenticator(App, {
  usernameAttributes: "email",
  signUpConfig: {
    hiddenDefaults: ['phone_number'],
    signUpFields: [
      {
        label: "Nickname",
        key: 'nickname',
        required: true,
        displayOrder: 1,
        type: 'string'
      }
    ]
  }
});
