import React from 'react';
import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";
import ContentItemPage from '../ContentItemPage';
import AllContent from '../AllContent';
import { Grid, Segment, Table } from 'semantic-ui-react';
import InboxEmailItemList from './InboxEmailItemList';

function Inbox(props) {
    let { path, url } = useRouteMatch();
    return (
        <div>
            <Grid className="email-inbox-table">
                    <Grid.Row>
                        <Grid.Column width={4} className="email-list">
                            <Segment style={{padding:0, overflow: 'auto', maxHeight: 600 }}>
                                <InboxEmailItemList content={props.content} />
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Segment style={{overflow: 'auto', maxHeight: 600 }}>
                                <Switch>
                                    <Route exact path={path} children={<div></div>} />
                                    <Route path={`${path}/:id`} children={<ContentItemPage content={props.content} />} />
                                </Switch>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
            </Grid>
        </div>
    );
}

export default Inbox;
