import React from 'react';
import {
    Switch,
    Route,
    useRouteMatch
} from "react-router-dom";
import Document from './Document';
import { Grid, Segment } from 'semantic-ui-react';
import FileGrid from "../../FileGrid";

function Documents(props) {
    let { path, url } = useRouteMatch();

    const files = [];

    if (props.content) {
        for (let index = 0; index < props.content.length; index++) {
            const doc = props.content[index];
            console.log(doc);
            console.log(doc.props.title);
            files.push({
                filename: doc.props.title,
                filedate: doc.props.date,
                color: "#ffbf0f",
                link: doc.props.id
            });
        }
    }

    const click = (file) => {
        console.log("Clicked on file", file);
    }

    return (
        <div>
            <Grid>
                    <Grid.Row>
                        <Grid.Column width={4} className="document-grid">
                            <Segment style={{padding:0, overflow: 'auto', maxHeight: 600 }}>
                                <FileGrid 
                                    files={files}
                                    contextMenu={[]}
                                    onClickFile={click}
                                />
                            </Segment>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Segment style={{overflow: 'auto', maxHeight: 600 }}>
                                <Switch>
                                    <Route exact path={path} children={<div></div>} />
                                    <Route path={`${path}/:id`} children={<Document content={props.content} />} />
                                </Switch>
                            </Segment>
                        </Grid.Column>
                    </Grid.Row>
            </Grid>
        </div>
    );
}

export default Documents;
