import React, {useEffect, useState} from 'react';
import { Form, Segment, Header, Button } from 'semantic-ui-react';
import GroupService from '../data/groups/GroupService';
import groupStrings from '../data/groups/groupStrings';
import GroupMemberTable from './GroupMemberTable';

function GroupManagementSection(props) {

    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [dirty, clean] = useState(false);

    async function refreshCurrentGroups() {
        console.log("refreshing groups");
        await GroupService.ListAllGroups().then(async (result) => {
            if (!result.data || !result.data.listGroups) return;
            let listedGroups = result.data.listGroups.items.map(function(g) {
                return {
                    key: g.id,
                    text: g.name,
                    value: g.id
                };
            });
            listedGroups.sort((a, b) => {return a.text > b.text ? 1 : -1 });
            setGroups(listedGroups);
        });
    };
    useEffect(() => {
        refreshCurrentGroups();
        
    }, [dirty]);

    const onSelectGroup = (e, {value}) => {

        let group = groups.find(x => x.value == value);
        console.log(group);
        if (!group) return;
        setSelectedGroup(group);
    }

    

    return (
        <Segment>
            <Header dividing as="h2">
                Move Users
            </Header>
            <Form>
                <Form.Dropdown fluid selection placeholder={groupStrings["en-us"]['group-selection-dropdown']}
                    options={groups ?? []} onChange={onSelectGroup} value={selectedGroup ? selectedGroup.value : null}/>
                <GroupMemberTable selectedGroup={selectedGroup}/>
            </Form>
        </Segment>
    );
}

export default GroupManagementSection;