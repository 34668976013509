import React from "react";
import DatabaseService from './DatabaseService';
import awsconfig from '../../../src/aws-exports';
import Episode0 from "../content/episodes/test/Episode0";
import Episode1 from "../content/episodes/test/Episode1";
import EmailTemplate from "../content/episodes/EmailTemplate"
import DocumentTemplate from "../content/documents/DocumentTemplate"
import ImageTemplate from "../content/documents/ImageTemplate";
import VideoTemplate from "../content/documents/VideoTemplate";
import TaskTemplate from "../content/tasks/TaskTemplate";
import ContentAvailabilityService from "./ContentAvailabilityService";
import { Auth } from 'aws-amplify';
import Document0 from "../content/documents/test/Document0";
import AirFilterTask from "../content/tasks/test/AirFilterTask";
import EpisodePharosTest from "../content/episodes/test/EpisodePharosTest";
import PharosTask from "../content/tasks/test/PharosTask";
import ReflectionForm1 from "../content/episodes/test/ReflectionForm1";
import { ConsoleLogger } from "@aws-amplify/core";


class AWSContentFetcher {
    
    constructor() {
        this.databaseService = new DatabaseService();
        this.contentAvailabilityService = new ContentAvailabilityService();

        this.episodeComponents = { // old var - unused
            Episode0: <Episode0></Episode0>,
            Episode1: <Episode1></Episode1>,
            EpisodePharosTest: <EpisodePharosTest></EpisodePharosTest>,
            ReflectionForm1: <ReflectionForm1></ReflectionForm1>
        };

        this.documentComponents = { // old var - unused
            Document0: <Document0></Document0>
        };
 
        this.taskComponents = { //old var - unused
            AirFilterTask: <AirFilterTask></AirFilterTask>,
            PharosTask: <PharosTask></PharosTask>
        }
    }

    filterIfNotAdmin(unfilteredItems, isAdmin) {
        return isAdmin ? unfilteredItems : unfilteredItems.filter(function(item) {
            return item.Sent.BOOL;
        });
    }

    //retreive file from s3 bucket

    getFileType(filename) {
        if (filename.indexOf(".txt") != -1) return "txt";
        if (filename.indexOf(".mp4") != -1) return "mp4";
        if (filename.indexOf("youtube.com") != -1) return "yt";
        if (filename.indexOf(".pdf") != -1) return "pdf";
        if (filename.indexOf(".jpg") != -1) return "jpg";
        if (filename.indexOf(".html") != -1) return "html";
        return null;
    }

    async getS3(filename, filetype) {
        //get from: "https://argamplify-content.s3.amazonaws.com/" + filename
        var url = "https://argamplify-content.s3.amazonaws.com/"+filename;

        const response = await fetch(url);
        //console.log(response)
        if (filetype == "txt") {
            const responseJson = await response.text();
            return responseJson; 
        }
        if (filetype == "json") {
            const responseJson = await response.json();
            return responseJson; //nothing uses json in here
        }
        return null;
    }


    //parse email text into html
    /*
    Email file syntax: to link to a document, use:
    {{document:file+name+of+doc.extension,text:Display Note Text}}
    To link to a task, use:
    {{task:file+name+of+task.html,text:Display Note Text}}
    */
    parseEmail(text) {
        text = text.replaceAll("\n","<br />"); //add in line breaks

        while (text.indexOf("{{") != -1) {
            var start = text.indexOf("{{");
            var end = text.indexOf("}}");
            var sequence = text.substring(start,end+2);
          	var params = this.getParams(sequence);
          
          	var route = "documents";//default route
            var file = "https://www.youtube.com/embed/dQw4w9WgXcQ"; //default file ;)
            var displayText = "Click Here";//default display note
            var type = "embed"; //link or embed
            
            params.forEach((p)=> {
                if (p.key.includes("document")) {
                    route = "documents"; //documents route
                    file = p.value;
                    type = "link";
                }
                else if (p.key.includes("task")) {
                    route = "tasks";
                    file = ""; //no file to route to
                    type = "link";
                }
                else if (p.key.includes("video")) {
                    type = "embed";
                    file = p.value;
                }
                else if (p.key.includes("text")) {
                    displayText = p.value;
                }
            });
			
            //switch out text to html
            if (type == "link") {
                var linkTag = `<a href="#/${route}/${file}">${displayText}</a>`
                text = text.replace(sequence,linkTag);
            }
            else if (type == "embed") {
                var embedTag = `<iframe width="560" height="315" src="${file}" 
                title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; 
                encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`
                text = text.replace(sequence,embedTag);
            }         
        }
        return text;
    }
    //input format: {{name:arky,item2:data,item3:etc}}
    getParams(text) {
        var params = [];
          var ind = 2;
          while (text.indexOf(",", ind) != -1) {
            var p = text.substring(ind,text.indexOf(",",ind));
            params.push({
              key : p.substring(0,p.indexOf(":")),
              value : p.substring(p.indexOf(":")+1)
            })
            ind = text.indexOf(",",ind) + 1
        }
          var p = text.substring(ind,text.length-2);
        params.push({
          key : p.substring(0,p.indexOf(":")),
          value : p.substring(p.indexOf(":")+1)
        })
         return params;
    }

    //generate email component from template
    async generateEpisodeComponent(episode) {
        var filetype = this.getFileType(episode.component);
        var content = await this.getS3(episode.component, filetype);
        console.log(filetype)
        if (filetype == "txt") {
            //parse the content string
            var contentHTML = this.parseEmail(content);

            var component = <EmailTemplate body={contentHTML} title={episode.name} id={episode.component} key={episode.component}
                            emailPreview={content.substring(0,20)+"..."} isAdmin={false} sender={""}></EmailTemplate>
            return component;
        }
        if (filetype == "mp4") { //old code
            var contentHTML = `<video width="400" controls>
            <source src="https://argamplify-content.s3.amazonaws.com/${episode.component}" type="video/mp4">
            </video>`
            var component = <EmailTemplate body={contentHTML} title={episode.name} id={episode.component} key={episode.component}
            emailPreview={"Video"} isAdmin={false} sender={""}></EmailTemplate>
            return component;
        }
        //var c = <EmailTemplate body={body}></EmailTemplate>
    }

    //generate document component from template
    async generateDocumentComponent(document) {
        var filetype = this.getFileType(document.component);
        if (filetype == "pdf") {
            var contentHTML = `https://argamplify-content.s3.amazonaws.com/${document.component}`;
            var component = <DocumentTemplate body={contentHTML} title={document.name} id={document.component} key={document.component}
            isAdmin={false}></DocumentTemplate>
            return component;
        }
        if (filetype == "jpg") {
            var contentHTML = `https://argamplify-content.s3.amazonaws.com/${document.component}`;
            var component = <ImageTemplate body={contentHTML} title={document.name} id={document.component} key={document.component}
            isAdmin={false}></ImageTemplate>
            return component;
        }
        if (filetype == "mp4") {
            var contentHTML = `https://argamplify-content.s3.amazonaws.com/${document.component}`;
            var component = <VideoTemplate body={contentHTML} title={document.name} id={document.component} key={document.component}
            isAdmin={false}></VideoTemplate>
            return component;
        }
        if (filetype == "yt") {
            var contentHTML = `<iframe width="100%" height="315" src="${document.component}" 
            title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; 
            encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
            var ind = document.component.indexOf("embed/")+6;
            var component = <EmailTemplate body={contentHTML} title={document.name} id={"yt-"+document.component.substring(ind)} key={document.component}
            isAdmin={false}></EmailTemplate>
            return component;
        }
    }

    //generate task component from template
    async generateTaskComponent(task) {
        //only allows html file type
        var content = `https://argamplify-content.s3.amazonaws.com/${task.component}`;
        var component = <TaskTemplate body={content} title={task.name} id={task.component} key={task.component}
        isAdmin={false}></TaskTemplate>
        return component;
    }

    async shouldContentUpdate() {
        let self = this;
        return new Promise((resolve) => {
            let getTimestampRequest = self.dynamoDbService.getLastUpdatedTime();
            getTimestampRequest.on('success', function(dbResponse) {
                let localLastUpdated = localStorage.getItem('lastUpdated');
                if (!localLastUpdated) { localLastUpdated = 0; }
                let contentLastUpdated = dbResponse.data.Items[0].LastUpdated.S;
                if (Date.parse(contentLastUpdated) > Date.parse(localLastUpdated)) {
                    resolve(true);
                    console.log("Should update!")
                }
            });
            
            getTimestampRequest.send();
        });
    }

    updateLocalTimestamp() {
        localStorage.setItem('lastUpdated', new Date());
    }

    fetchEpisodeContent() {
        console.log("fetching")
        return new Promise((resolve) => {
            Auth.currentAuthenticatedUser().then((result) => {
                var groups = result.signInUserSession.idToken.payload["cognito:groups"];
                if (groups && groups.includes("Admin") && false) {//don't handle the administrator episodes here
                    resolve(Object.values(this.episodeComponents));// old handler for admin episodes, moved to contentAvailabilityService.js
                }
                else {
                    this.contentAvailabilityService.getAvailableEpisodesForUser(result.username)
                    .then(async (episodes) => {
                        var componentNames = episodes.map((episode) => episode.component);
                        //var components = componentNames.map((name) => this.episodeComponents[name]);
                        var components = [];
                        for (var i = 0; i < episodes.length; i++) {
                            var episode = episodes[i];
                            if (this.getFileType(episode.component) != null) {
                                var e = await this.generateEpisodeComponent(episode); 
                                components.push({component: e, date: episode.scheduledTime})
                                //minor issue: adds episodes async (out of order)
                            }
                            //don't retreive if not a valid file
                        }
                        //sort episodes by release date
                        components.sort(function(a,b){
                            return new Date(b.date) - new Date(a.date); //most recent ordered first
                          });
                        console.log(components)
                        resolve(components.map((e) => e.component));
                    });
                }
            });
        });
    }

    fetchDocumentContent() {
        return new Promise((resolve) => {
            Auth.currentAuthenticatedUser().then((result) => {
                var groups = result.signInUserSession.idToken.payload["cognito:groups"];
                if (groups && groups.includes("Admin") && false) {//don't handle the administrator episodes here
                    resolve(Object.values(this.documentComponents));//admins have no rights
                }
                else {
                    this.contentAvailabilityService.getAvailableDocumentsForUser(result.username)
                    .then(async (documents) => {
                        var componentNames = documents.map((document) => document.component);
                        //var components = componentNames.map((name) => this.documentComponents[name]);
                        var components = [];
                        for (var i = 0; i < documents.length; i++) {
                            var document = documents[i];
                            if (this.getFileType(document.component) != null) {
                                var e = await this.generateDocumentComponent(document); 
                                components.push(e) //minor issue: adds episodes async (out of order)
                            }
                        }
                        resolve(components);
                    });
                }
            });
        });
    }

    fetchTaskContent() {
        return new Promise((resolve) => {
            Auth.currentAuthenticatedUser().then((result) => {
                var groups = result.signInUserSession.idToken.payload["cognito:groups"];
                if (groups && groups.includes("Admin") && false) {//don't handle the administrator episodes here
                    resolve(Object.values(this.taskComponents)); //treat them like everybody else
                }
                else {
                    this.contentAvailabilityService.getAvailableTasksForUser(result.username)
                    .then(async (tasks) => {
                        var components = [];
                        for (var i = 0; i < tasks.length; i++) {
                            if (this.getFileType(tasks[i].component) != null) {
                                var e = await this.generateTaskComponent(tasks[i]); 
                                components.push(e);
                            }
                        }
                        resolve(components);
                    });
                }
            });
        });
    }

    deduplicateMessages(messages) {
        let ids = messages.map(m => m.MessageAttributes["Id"].StringValue);
        let uniqueIds = Array.from(new Set(ids));
        let uniqueMessages = uniqueIds.map((id) => {
            return messages.find(function(element) {
                return element.MessageAttributes["Id"].StringValue === id;
            })
        })
        return uniqueMessages;
    }

    retrieveS3Content(objectName, Id, isAdmin) {
        return this.s3Retriever.getObject(awsconfig["aws_content_delivery_bucket_region"], 
            awsconfig["aws_content_delivery_bucket"], objectName, Id, isAdmin);
    }

}

export default AWSContentFetcher;