import React, {useState, useEffect} from 'react';
import { Checkbox, Icon, Grid } from 'semantic-ui-react';
import UserService from '../data/users/UserService';

function EnableDisableUserOptions(props) {
    const [toggled, toggle] = useState(false);
    const [user, setUser] = useState({});

    useEffect(() => {
        const fetchUser = async() => {
            let getUserResult = await UserService.GetUser(props.user.Username);
            setUser(getUserResult);
        }
        fetchUser();
    }, []);

    const enabled = user.Enabled;
    console.log(user);

    let checked = enabled; 
    if (toggled) checked = !checked;

    const toggleEvent = (event) => {
        console.log("toggled"); 
        toggle(!toggled);
        props.onToggle(user.Username, user.Enabled);
    };

    return (<Grid>
        <Grid.Row >
            <Grid.Column width="3" >{props.user.Email}</Grid.Column>
            <Grid.Column ><Checkbox checked={checked} onChange={toggleEvent} /></Grid.Column>
            <Grid.Column >{toggled ? <Icon name={user.Enabled ? "remove" : "add"} /> : ""}</Grid.Column>
        </Grid.Row>
    </Grid>);
}

export default EnableDisableUserOptions;