class DynamoDbService {

    async eraseComment(params) {
        var AWS = require('aws-sdk');
        var dynamoDb = new AWS.DynamoDB();
        return dynamoDb.updateItem(params);
    }

    async deleteComment(contentId, commentId) {
        var AWS = require('aws-sdk');
        var dynamoDb = new AWS.DynamoDB();

        const params = {
            Key: {
                "CommentId": {S: commentId},
                "ContentId": {S: contentId}
            },
            TableName: "ContentComment"
        };
        
        console.log(params);
        return dynamoDb.deleteItem(params);
    }

    async addComment(contentId, commentText, commentAuthor, parentCommentId = undefined) {
        var AWS = require('aws-sdk');
        var dynamoDb = new AWS.DynamoDB();
        // generate unique id
        const uuidv1 = require('uuid/v1');
        const id = uuidv1();
        // grab timestamp
        var timeStamp = Date.now();

        let item = {
            "CommentId": {S: id},
            "ContentId": {S: contentId},
            "CommentText": {S: commentText},
            "Author": {S: commentAuthor},
            "TimeStamp": {N: timeStamp.toString()}
        }
        if (parentCommentId) {
            item["ParentCommentId"] = {S: parentCommentId};
        }
        const params = {
            TableName: "ContentComment",
            Item: item
        };
        
        console.log(params);
        return dynamoDb.putItem(params);
    }

    async getCommentsForContentItem() {
        var AWS = require('aws-sdk');
        var dynamoDb = new AWS.DynamoDB();

        let params = {
            TableName: "ContentComment"
        };
        return dynamoDb.scan(params);
    }

    // Until we have a more complex user structure, just get everything
    getAllContent() {
        var AWS = require('aws-sdk');
        console.log(AWS.config);
        var dynamoDb = new AWS.DynamoDB();

        let params = {
            TableName: "Content"
        };
        return dynamoDb.scan(params);
    }

    updateItem(params) {
        var AWS = require('aws-sdk');
        var dynamoDb = new AWS.DynamoDB();
        dynamoDb.updateItem(params, function(err, data) {
            if (err) console.log(err)
            else {
                console.log(data);
            }
        })
    }

    getLastUpdatedTime() {
        let params = {
            TableName: "ContentUpdateLog"
        };
        var AWS = require('aws-sdk');
        var dynamoDb = new AWS.DynamoDB();
        return dynamoDb.scan(params);
    }

    setLastUpdatedTime(time) {
        let timeString = time.toString();
        let params = {
            Key: {
                "Id" : {
                    S: '0'
                }
            },
            ExpressionAttributeValues: {
                ":now": {
                    S: timeString
                }
            }, 
            TableName: "ContentUpdateLog",
            UpdateExpression: 'SET LastUpdated = :now'
        };
        this.updateItem(params);
    }

    insertScheduledSendContent(params) {
        var AWS = require('aws-sdk');
        var dynamoDb = new AWS.DynamoDB();
        return dynamoDb.putItem(params);
    }
}

export default DynamoDbService;