import React, {useState} from 'react';
import classnames from 'classnames';
import { Button } from 'semantic-ui-react';



const CommentReplies = (props) => {
    const [open, setOpen] = useState(false);

    const toggleRepliesVisibility = (event) => {
        setOpen(!open);
    }
    
    if (!props) return '';
    if (!props.replies) return '';
    if (props.replies.length <= 0) return '';

    return (
        <div className="comment-replies-section">
            <Button basic onClick={toggleRepliesVisibility}>{open ? 'Hide' : 'Show'} replies</Button>
            {open ? <div className="comment-replies-list">
                {props.replies}
            </div> : ""}
        </div>
    );
}


export default CommentReplies;