import React, {useEffect, useState} from 'react';
import { Header, Form, Dropdown } from 'semantic-ui-react';
import GroupManagementSection from './GroupManagementSection';
import CreateGroupSection from './CreateGroupSection';
import DeleteGroupSection from './DeleteGroupSection'
function GroupsSection() {

    return (
        <div>
            <Header dividing as="h1">
                GROUP MANAGEMENT
            </Header>
            <GroupManagementSection />
            <CreateGroupSection />
            <DeleteGroupSection />
        </div>
    );
}

export default GroupsSection;