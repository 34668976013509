import React, { useEffect, useState } from 'react';
import DatabaseService from '../../data/DatabaseService';
import CommentSection from '../Comment/CommentSection';

const MissionDayChat = (props) => {
    let [ comments, setComments ] = useState([]);
    
    function setReplies(comments) {
        for (let c of comments) {
            // find comment children
            let replies = comments.filter(x => x.parentCommentId && x.parentCommentId === c.id);
            c.Replies = replies;
        }
    }
    
    useEffect(() => {
        const fetchComments = async () => {
            const databaseService = new DatabaseService();
            let listCommentsResult = await databaseService.ListComments();
            console.log(listCommentsResult);
            let allComments = listCommentsResult.data.listComments.items;
            
            if (allComments.length <= 0) return;
            
            allComments.sort((a, b) => {
                return a.timestamp > b.timestamp ? -1 : 1;
            });
            setReplies(allComments);
            setComments(allComments.filter((x) => {
                console.log(x);
                return (x.contentPage === props.episode.name && !x.parentCommentId);
            }));
        }
        fetchComments();
    }, [props]);
    return <div>
        <CommentSection comments={comments} contentId={props.episode.name} />
    </div>
}

export default MissionDayChat;