import React, {useState} from 'react';
import DynamoDbService from '../../data/DynamoDbService';
import { Auth } from 'aws-amplify';
import DatabaseService from '../../data/DatabaseService';
import { createComment } from '../../../graphql/mutations';
import { Button } from 'semantic-ui-react';

const getUserName = async () => {
    let user = await Auth.currentAuthenticatedUser();
    let username = user.attributes["email"];

    return username;
}

const addComment = async (comment, contentId, parentCommentId=undefined) => {
    let dynamoDbService = new DynamoDbService();
    let databaseService = new DatabaseService();
    let username = await getUserName();

    // text: String!
    // author: String!
    // timestamp: Int!
    // contentPage: ContentPage @connection(name: "ContentPageComments")

    let now = new Date();

    let createCommentInput = {
        text: comment,
        author: username,
        timestamp: now,
        contentPage: contentId
    };
console.log(createCommentInput);
    if (parentCommentId) {
        createCommentInput.parentCommentId = parentCommentId;
    }

    await databaseService.CreateComment(createCommentInput);
    // let addCommentRequest = dynamoDbService.addComment(contentId, comment, username, parentCommentId);
    // addCommentRequest.then(function(request) {
    //     request.send(function(err, data) {
    //         if (err) {
    //             console.log(err);
    //         }
    //     });
    // });
    
    window.location.reload();
}

const ComposeCommentSection = (props) => {

    const [commentText, setCommentText] = useState(undefined);
    const discardButton = props.isReply ? <button onClick={props.onDiscardClicked}>Discard</button> : '';

    return (
        <div className={"compose-comment"}>
            <h4>{props.isReply ? "Reply..." : "Post a comment"}</h4>
            <textarea onChange={(event) => setCommentText(event.target.value)} placeholder="Write a comment"></textarea>
            <div className="compose-comment-buttons">
                <Button basic onClick={() => addComment(commentText, props.contentId, props.parentCommentId)} className="post-comment-button">Post</Button>
                {discardButton}
            </div>
        </div>
    );
}

export default ComposeCommentSection;