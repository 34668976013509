import React, {Component, useState} from 'react';
import { Button, Container, Segment } from 'semantic-ui-react';
const ImageTemplate = (props) =>  {
    return (
        <div>
            <img width="100%" src={props.body}/>
        </div>
    );
}

export default ImageTemplate;