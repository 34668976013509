import React, {useEffect, useState} from 'react';
import { Grid, Header } from 'semantic-ui-react';
import UserService from '../data/users/UserService';
import EnableDisableUsersSection from "./EnableDisableUsersSection";
import ModifyUserRolesSection from "./ModifyUserRolesSection";
import CreateUsersSection from "./CreateUsersSection";
import InvitationEmailSection from "./InvitationEmailSection";
import DeleteOwnAccountSection from './DeleteOwnAccountSection';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Auth } from 'aws-amplify';

function UsersSection() {
    const [newUsers, setNewUsers] = useState([]);
    const [currentUsers, setCurrentUsers] = useState([]);
    const [toggledUsers, setToggledUsers] = useState([]);
    const [dirty, clean] = useState(false);


    async function removeSelfFromUserList(users) {
        let userInfo = await Auth.currentUserInfo();
        console.log(userInfo);
        let filteredUsers = users.filter(user => user.cognitoId != userInfo.username);
        return filteredUsers;
    }

    async function refreshCurrentUsers() {
        console.log("refreshing users");
        await UserService.ListAllUsers().then(async (data) => {
            if (!data) return;
            console.log(data);
            var usersWithoutSelf = await removeSelfFromUserList(data);

            let usernames = usersWithoutSelf.map((user) => {
                return  {
                    "Username": user.cognitoId,
                    "Email": user.name,
                    "Enabled": user.Enabled
                };
            });
            usernames.sort((x, y) => {
                return y.Enabled - x.Enabled;
            });
            console.log(usernames);
            setCurrentUsers(usernames);
        });
    };
    useEffect(() => {
        refreshCurrentUsers();
        
    }, [dirty]);

    const removeNewUser = (e, index) => {
        console.log("removing user from index " + index);
        setNewUsers(users => users.filter((u) => u.index !== index));
        console.log(newUsers);
    };

    const addNewUser = () => {
        let existingIndices = newUsers.map((user) => user.index);
        console.log(existingIndices);
        let indexToAdd = existingIndices.length > 0 ? Math.max(...existingIndices) + 1
            : 0;
        console.log("adding a user to index " + (indexToAdd));
        setNewUsers((previousNewUsers) => {
            return [...previousNewUsers, {email: "", index: indexToAdd}];
        });
        console.log("added a new user to the list");
    };
    
    const onNewUserEmailChange = (e, index) => {
        newUsers[index].email = e.target.value;
    };

    const modifyUserRoles = async (e, data, roleModifications) => {
        let numModified = 0;

        for (const mod of roleModifications) {
            numModified++;
            // remove from old role group
            await UserService.RemoveUserFromGroup(mod.username, mod.oldRole).then(async () => {
                // add to new role group
                await UserService.AddUserToGroup(mod.username, mod.newRole);
            });
            mod.callback(mod.newRole);
        }
        if (numModified > 0) {
            toast.success("User role(s) modified.");
        }
        else {
            toast.info("No roles were modified.")
        }
        roleModifications = [];
        numModified = 0;
    }

    const createNewUser = (user) => {
        console.log(user);
        let validator = require("email-validator");
        if (!user.nickname || !user.email || !user.role) return;
        if (!validator.validate(user.email)) return;
        var userButInAnArray = [user];
        UserService.CreateNewUsers(userButInAnArray, user.role).then((result) => {
            console.log(result);
            if (result.type == "success"){
                toast.success(result.text);
            }
            else {
                toast.warn(result.text);
            }
        }).catch((reason) => {
            toast.error(reason.text);
        });

        setNewUsers([]);
    }

    const createNewUsers = () => {
        let validator = require("email-validator");
        let nonBlankNewUsers = newUsers.filter((user) => validator.validate(user.email));
        if (nonBlankNewUsers.length <= 0) return;
        
        UserService.CreateNewUsers(nonBlankNewUsers, "Player").then((result) => {
            console.log(result);
            if (result.type == "success"){
                toast.success(result.text);
            }
            else {
                toast.warn(result.text);
            }
        }).catch((reason) => {
            toast.error(reason.text);
        });

        setNewUsers([]);
    }

    const respondToToggle = (username, wasEnabled) => {
        let index = toggledUsers.findIndex((toggledUser) => toggledUser.Username == username);
        let newToggledUsers = toggledUsers;
        if (index != -1) {
            // user was toggled, un-toggle
            newToggledUsers.splice(index, 1);
        }
        else {
            // add to toggled list
            newToggledUsers.push({"Username": username, "WasEnabled": wasEnabled});
        }
    }

    const enableDisableUsers = () => {
        UserService.EnableDisableUsers(toggledUsers).then(() => {
            setToggledUsers([]);
            clean(!dirty);
            toast.success("User active status updated.");
        });
    }

    return (
        <div>
            <Header dividing as="h1">
                USER MANAGEMENT
            </Header>
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column width="5">
                        <CreateUsersSection newUsers={newUsers} createNewUsersFunction={createNewUser} />
                    </Grid.Column>
                    <Grid.Column width="10" className="user-management-section">
                        <EnableDisableUsersSection users={currentUsers} onToggle={respondToToggle} enableDisableUsersFunction={enableDisableUsers} />
                        <ModifyUserRolesSection onSubmit={modifyUserRoles} currentUsers={currentUsers} />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width="7" className="user-management-section">
                        <InvitationEmailSection />
                    </Grid.Column>
                    <Grid.Column width="8" className="user-management-section">
                        <DeleteOwnAccountSection />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </div>
    );
}

export default UsersSection;