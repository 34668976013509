import React from 'react';
import { Link, useRouteMatch } from "react-router-dom";
import ContentItem from '../ContentItem';

const InboxEmailItem = (props) => {
    let { path, url } = useRouteMatch();
    return (
        <div className="content-item">
            <Link to={`${url}/${props.id}`}><ContentItem {...props} /></Link>
        </div>
    );
}

export default InboxEmailItem;