/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getEpisode = /* GraphQL */ `
  query GetEpisode($id: ID!) {
    getEpisode(id: $id) {
      id
      index
      name
      contentPages {
        items {
          id
          title
          s3ObjectName
          sent
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listEpisodes = /* GraphQL */ `
  query ListEpisodes(
    $filter: ModelEpisodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEpisodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        index
        name
        contentPages {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContentPage = /* GraphQL */ `
  query GetContentPage($id: ID!) {
    getContentPage(id: $id) {
      id
      title
      s3ObjectName
      sent
      episode {
        id
        index
        name
        contentPages {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listContentPages = /* GraphQL */ `
  query ListContentPages(
    $filter: ModelContentPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContentPages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        s3ObjectName
        sent
        episode {
          id
          index
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      parentCommentId
      text
      author
      timestamp
      contentPage
      createdAt
      updatedAt
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        parentCommentId
        text
        author
        timestamp
        contentPage
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getScheduledSendContent = /* GraphQL */ `
  query GetScheduledSendContent($id: ID!) {
    getScheduledSendContent(id: $id) {
      id
      s3ObjectName
      createdAt
      updatedAt
    }
  }
`;
export const listScheduledSendContents = /* GraphQL */ `
  query ListScheduledSendContents(
    $filter: ModelScheduledSendContentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScheduledSendContents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        s3ObjectName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getContentUpdateLog = /* GraphQL */ `
  query GetContentUpdateLog($id: ID!) {
    getContentUpdateLog(id: $id) {
      id
      content {
        id
        title
        s3ObjectName
        sent
        episode {
          id
          index
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      LastUpdated
      createdAt
      updatedAt
    }
  }
`;
export const listContentUpdateLogs = /* GraphQL */ `
  query ListContentUpdateLogs(
    $filter: ModelContentUpdateLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContentUpdateLogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        content {
          id
          title
          s3ObjectName
          sent
          createdAt
          updatedAt
        }
        LastUpdated
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGame = /* GraphQL */ `
  query GetGame($id: ID!) {
    getGame(id: $id) {
      id
      invitationEmailTemplate
      createdAt
      updatedAt
    }
  }
`;
export const listGames = /* GraphQL */ `
  query ListGames(
    $filter: ModelGameFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGames(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        invitationEmailTemplate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($gameId: ID!, $id: ID!) {
    getGroup(gameId: $gameId, id: $id) {
      id
      gameId
      name
      createdAt
      updatedAt
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $gameId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGroups(
      gameId: $gameId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        gameId
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($gameId: ID!, $name: String!) {
    getUser(gameId: $gameId, name: $name) {
      id
      cognitoId
      gameId
      groupId
      role
      name
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $gameId: ID
    $name: ModelStringKeyConditionInput
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      gameId: $gameId
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        cognitoId
        gameId
        groupId
        role
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSchedule = /* GraphQL */ `
  query GetSchedule($gameId: ID!, $id: ID!) {
    getSchedule(gameId: $gameId, id: $id) {
      id
      gameId
      groupId
      json
      lastChecked
      createdAt
      updatedAt
    }
  }
`;
export const listSchedules = /* GraphQL */ `
  query ListSchedules(
    $gameId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSchedules(
      gameId: $gameId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        gameId
        groupId
        json
        lastChecked
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFcmToken = /* GraphQL */ `
  query GetFcmToken($userId: ID!, $id: ID!) {
    getFCMToken(userId: $userId, id: $id) {
      id
      userId
      token
      subscribed
      createdAt
      updatedAt
    }
  }
`;
export const listFcmTokens = /* GraphQL */ `
  query ListFcmTokens(
    $userId: ID
    $id: ModelIDKeyConditionInput
    $filter: ModelFCMTokenFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFCMTokens(
      userId: $userId
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        token
        subscribed
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPlayerData = /* GraphQL */ `
  query GetPlayerData($gameId: ID!, $userName: String!) {
    getPlayerData(gameId: $gameId, userName: $userName) {
      gameId
      userName
      json
      createdAt
      updatedAt
    }
  }
`;
export const listPlayerDatas = /* GraphQL */ `
  query ListPlayerDatas(
    $gameId: ID
    $userName: ModelStringKeyConditionInput
    $filter: ModelPlayerDataFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPlayerDatas(
      gameId: $gameId
      userName: $userName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        gameId
        userName
        json
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
