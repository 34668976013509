import React, {Component} from 'react';
import Unity, { UnityContext } from "react-unity-webgl";
import { Segment } from 'semantic-ui-react';

const AirFilterTask = (props) => {
    let unityContext = new UnityContext({
        loaderUrl: "airfilter_prototype/Build/Build.loader.js",
        codeUrl: "airfilter_prototype/Build/Build.wasm",
        frameworkUrl: "airfilter_prototype/Build/Build.framework.js",
        dataUrl: "airfilter_prototype/Build/Build.data",
        streamingAssetsUrl: "airfilter_prototype/StreamingAssets"
    });

    let unityCanvas = <Unity unityContext={unityContext}
        style={{ width: "1200px", height: "675px" }}
        className={"episode1-unity-canvas"} />;

    return <div>
        <Segment textAlign="center">
            {unityCanvas}
        </Segment>
    </div>
}
AirFilterTask.title = "Air Filter Repair";
export default AirFilterTask;