import React, {Component, useState} from 'react';
import { Button, Container, Segment } from 'semantic-ui-react';
import dummy from './PDFs/dummy.pdf';
const Document0 = (props) =>  {
    return (
        <div>
            <object width="100%" height="540" data={dummy} type="application/pdf"/>
        </div>
    );
}

Document0.id = "doc0";
Document0.isAdmin = false;
Document0.title = "Document 0";
Document0.date = "20 Nov 2107 12:24";

export default Document0;