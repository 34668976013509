import React, {Component} from 'react';
import IdentityService from '../data/IdentityService';

class ContentItem extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            item: props.item,
            isAdmin: IdentityService.isAdmin(),
            title: props.title,
            id: props.id,
            sent: props.sent,
            selectedDate: new Date(),
            preview: props.preview,
            sender: props.sender
        };
    }

    render() {
        return (
                <div className="email-preview-container">
                    <h2>{this.state.title}</h2>
                    <p>{this.state.sender}</p>
                    <p className="email-preview">{this.state.preview}</p>
                </div>
        );
      }
}

export default ContentItem;