/* The purpose of this service is to allow us to send SQS messages
 */
import AWS from "aws-sdk";
import awsconfig from '../../aws-exports'; //'./../../src/aws-exports';
import { Auth } from 'aws-amplify';
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import awsResources from '../../aws-resources';

class SQSMessagingService {

    constructor() {
      
    }

    static async sendMessage(messageText) {
      AWS.config.update({
        region: awsconfig["aws_appsync_region"]
      });
      
      let credentials = await Auth.currentCredentials();
      const sqs = new AWS.SQS({credentials: Auth.essentialCredentials(credentials)});
      let params = {
          MessageBody: messageText,
          QueueUrl: awsResources["dev-sqs-scheduleUpdateEndpoint"]
      };
      let sqsResult = await sqs.sendMessage(params).promise();
      console.log(sqsResult);
    }

    static async setupSQSConsumer(sqs) {
        const { Consumer } = require('sqs-consumer');
        const sqsHandler = Consumer.create({
          queueUrl: awsResources["dev-sqs-scheduleUpdateEndpoint"],
          handleMessage: async (message) => {
            // TODO: filter the message: only show if the user is included in the target
            
            // toast!
            toast("Schedule updated!");
    
            localStorage.removeItem("schedule"); // this will force a refresh from the db
          },
          sqs: sqs
        });
    
        sqsHandler.on('error', (err) => {
          console.error(err.message);
        });
    
        sqsHandler.on('processing_error', (err) => {
          console.error(err.message);
        });
    
        sqsHandler.start();
      }

}
export default SQSMessagingService;