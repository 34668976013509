import React, {useEffect, useState} from 'react';
import { Header, Segment, Form, TextArea, Message, Button, Divider } from 'semantic-ui-react';
import EmailService from "../data/EmailService";
import UserService from "../data/users/UserService";
import { Auth } from 'aws-amplify';

function DeleteOwnAccountSection(props) {

    const deleteThisAccount = async () => {
        await UserService.DeleteCurrentUser();
    };

    return <Segment className="user-management-section no-border">
        <Header as="h1">Delete This Account</Header>
        <Divider />
        <Message>
            <p>Account deletion is <strong>permanent</strong> and <strong>irreversible</strong>.</p>
            <p>If you delete your account and you are the only Admin in a group, <strong>all other accounts in that group will also be deleted</strong>.</p>
        </Message>
        <Divider />
        <Form>
            <Button negative onClick={deleteThisAccount}>Delete</Button>
        </Form>
    </Segment>
}

export default DeleteOwnAccountSection;