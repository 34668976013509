import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Grid, Segment } from 'semantic-ui-react';

const EmailTemplate = (props) => {
    {
        return (
            <div>
                <Segment className="email-header">
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <h2>{props.title}</h2>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment className="email-body">
                    <p dangerouslySetInnerHTML={{__html:props.body}}></p>
                </Segment>
            </div>
        );
    }
};
/*


Go to link {{document:"(MISSION+DAY+1)+Mission+Dossier_+Water+Filter.pdf"}}

*/

export default EmailTemplate;