import React, {useEffect, useState} from 'react';
import { Form, Dropdown, Input, Button, Segment, Header, Label, Message } from 'semantic-ui-react';
import groupStrings from '../data/groups/groupStrings';
import GroupService from '../data/groups/GroupService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function DeleteGroupSection(props) {

    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [dirty, clean] = useState(false);

    async function refreshCurrentGroups() {
        console.log("refreshing groups");
        let allGroups = await GroupService.ListAllGroups();
        if (!allGroups.data || !allGroups.data.listGroups) return;
        let listedGroups = allGroups.data.listGroups.items.map(function(g) {
            return {
                key: g.name,
                text: g.name,
                value: g.id
            };
        });
        let ungroupedGroupIndex = listedGroups.findIndex(x => x.key == "Ungrouped");
        listedGroups.splice(ungroupedGroupIndex, 1);
        
        listedGroups.sort((a, b) => {return a.text > b.text ? 1 : -1 });

        setGroups(listedGroups);
    };
    useEffect(() => {
        refreshCurrentGroups();
        
    }, [dirty]);

    const onSelectGroup = (e, {value}) => {
        setSelectedGroup(value);
    }

    const deleteGroup = (e) => {
        if (!selectedGroup) return;
        GroupService.DeleteGroup(selectedGroup)
        .then((result) => {
            toast.success(groupStrings["en-us"]["group-deleted"]);
        })
        .catch((reason) => {
            toast.error(groupStrings["en-us"]["group-deletion-error"]);
        });
    }

    return <Segment>
        <Header dividing as="h2">
            Delete Group
        </Header>
        <Message>
            {groupStrings["en-us"]["group-deletion-warning"]}
        </Message>
        <Form onSubmit={deleteGroup}>
            <Form.Dropdown fluid selection placeholder={groupStrings["en-us"]['group-selection-dropdown']}
                options={groups ?? []} onChange={onSelectGroup} value={selectedGroup}/>
            <Button basic type="submit">Delete</Button>
        </Form>
    </Segment>
}

export default DeleteGroupSection;