const emailTemplates = {
    sources: {
        "en-us": "hr@callistomission.org"
    },
    subjects: {
        "en-us": "Welcome to the Callisto Project!"
    },
    messages: {
        "en-us": "PLACEHOLDER EMAIL: Welcome to the Callisto Project! Another email has been sent to you. It contains a temporary password that you can use to login. After you log in, you'll be required to change your password."
    }
};


export default emailTemplates;
