import React, {useEffect, useState} from 'react';
import { Header, Segment, Form, TextArea, Message, Button, Divider } from 'semantic-ui-react';
import EmailService from "../data/EmailService";
import UserService from "../data/users/UserService";
import { Auth } from 'aws-amplify';

function InvitationEmailSection(props) {
    const [introEmail, setIntroEmail] = useState();
    const [introEmailDraft, setIntroEmailDraft] = useState();

    useEffect(() => {
        loadIntroEmail();
    }, []);

    const loadIntroEmail = async () => {
        let gameId = await UserService.GetAttributeFromCurrentUser("custom:gameId");
        if (!gameId) return;
        
        console.log(gameId);
        let email = await EmailService.GetGameIntroEmail(gameId);
        setIntroEmail(email);
        return email;
    };

    const resetEmailField = async (resetEvent) => {
        console.log("resetting intro email field");
        console.log(resetEvent.target);
        let email = await loadIntroEmail();
        setIntroEmailDraft(email);
    }

    const updateIntroEmailDraft = (updateEvent) => {
        console.log("updated intro email draft");
        setIntroEmailDraft(updateEvent.target.value);
        console.log(updateEvent.target.value);
    }

    const saveIntroEmail = async() => {
        let user = await Auth.currentAuthenticatedUser();
        let attributes = await Auth.userAttributes(user);
        let gameId = attributes.find(a => a.Name == "custom:gameId");
        if (!gameId) return;
        await EmailService.SaveIntroEmailTemplate(gameId.getValue(), introEmailDraft);
        await loadIntroEmail();
    };

    return <Segment className="user-management-section no-border">
        <Form>
            <Header as="h1">Invitation Email</Header>
            <Divider />
            <Message>
                <p>When you add a user, two emails will be sent to them. </p>
                <p>One email contains a temporary password that the user will use to login, at which point they will be required to change their password.</p>
                <p>The other email contains an in-game introduction to The Callisto Project.</p>
                <p>Here, you can customize the in-game introduction.</p>
            </Message>
            <Form.Field 
                required control={TextArea} 
                onChange={updateIntroEmailDraft} 
                placeholder="Please write an introduction email." 
                value={introEmailDraft}
                defaultValue={introEmail} />
            <Divider />
            <Button basic onClick={saveIntroEmail}>Save</Button>
            <Button basic onClick={resetEmailField}>Reload</Button>
        </Form>
    </Segment>
}

export default InvitationEmailSection;