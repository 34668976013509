import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';

class DatabaseService{
    
    async ListContentPages() {
        const allContentPages = await API.graphql(graphqlOperation(queries.listContentPages));
        return allContentPages;
    }

    async ListUserFCMTokens(token) {
        const listUserFCMTokens = await API.graphql(graphqlOperation(queries.listFcmTokens, { token: token }));
        return listUserFCMTokens;
    }

    async CreateUserFCMToken(tokenObject) {
        const createUserFCMToken = await API.graphql(graphqlOperation(mutations.createFcmToken, { input: tokenObject }));
        return createUserFCMToken;
    }

    // Users
    async CreateUser(user) {
        console.log(user);
        const createUser = await API.graphql(graphqlOperation(mutations.createUser, {input: user}));
        return createUser;
    }

    async GetUser(getUserInput) {
        const getUser = await API.graphql(graphqlOperation(queries.getUser, {name: getUserInput.name, gameId: getUserInput.gameId}));
        return getUser;
    }

    async ListUsersInGame(listUsersInGameInput) {
        const listUsersInGame = await API.graphql(graphqlOperation(queries.listUsers, {filter: listUsersInGameInput}));
        return listUsersInGame;
    }

    async ListUsersInGroup(listUsersInGroupInput) {
        const listUsersInGroup = await API.graphql(graphqlOperation(queries.listUsers, {filter: listUsersInGroupInput}));
        return listUsersInGroup;
    }

    async UpdateUser(updateUserInput) {
        console.log(updateUserInput);
        const updateUser = await API.graphql(graphqlOperation(mutations.updateUser, {input: updateUserInput}));
        return updateUser;
    }

    // PlayerData
    async GetPlayerData(getPlayerDataInput) {
        const getPlayerData = await API.graphql(graphqlOperation(queries.getPlayerData, {gameId: getPlayerDataInput.gameId, userName: getPlayerDataInput.userName}));
        return getPlayerData;
    }

    async CreatePlayerData(createPlayerDataInput) {
        const createPlayerData = await API.graphql(graphqlOperation(mutations.createPlayerData, {input: createPlayerDataInput}));
        return createPlayerData;
    }

    async UpdatePlayerData(updatePlayerDataInput) {
        const updatePlayerData = await API.graphql(graphqlOperation(mutations.updatePlayerData, {input: updatePlayerDataInput}));
        return updatePlayerData;
    }

    // Games
    async CreateGame(createGameInput) {
        const createGame = await API.graphql(graphqlOperation(mutations.createGame, { input: createGameInput }));
        return createGame;
    }

    async GetGame(getGameInput) {
        const getGame = await API.graphql(graphqlOperation(queries.getGame, {id: getGameInput.id}));
        return getGame;
    }

    async UpdateGame(updateGameInput) {
        const updateGame = await API.graphql(graphqlOperation(mutations.updateGame, {input: updateGameInput}));
        return updateGame;
    }

    // Groups
    async GetGroupById(getGroupInput) {
        const getGroup = await API.graphql(graphqlOperation(queries.getGroup, {gameId: getGroupInput.gameId, id: getGroupInput.id}));
        return getGroup;
    }

    async ListGroups(listGroupsInput) {
        const listGroups = await API.graphql(graphqlOperation(queries.listGroups, {gameId: listGroupsInput.gameId}));
        return listGroups;
    }

    async CreateGroup(createGroupInput) {
        const createGroup = await API.graphql(graphqlOperation(mutations.createGroup, {input: createGroupInput}));
        return createGroup;
    }

    async DeleteGroup(deleteGroupInput) {
        const deleteGroup = await API.graphql(graphqlOperation(mutations.deleteGroup, {input: deleteGroupInput}));
        return deleteGroup;
    }

    async ListSchedules(listSchedulesInput) {
        const listSchedules = await API.graphql(graphqlOperation(queries.listSchedules, {filter: listSchedulesInput}));
        return listSchedules;
    }

    // Comments
    async ListComments() {
        const listComments = await API.graphql(graphqlOperation(queries.listComments));
        return listComments;
    }

    async CreateComment(createCommentInput) {
        const createComment = await API.graphql(graphqlOperation(mutations.createComment, { input: createCommentInput }));
        return createComment;
    }

    async DeleteComment(deleteCommentInput) {
        const deleteComment = await API.graphql(graphqlOperation(mutations.deleteComment, { input: deleteCommentInput }));
        return deleteComment;
    }

    async UpdateComment(updateCommentInput) {
        const updateComment = await API.graphql(graphqlOperation(mutations.updateComment, { input: updateCommentInput }));
        return updateComment;
    }
}

export default DatabaseService;