import React, {useEffect, useState} from 'react';
import { Table, Button } from 'semantic-ui-react';
import DatabaseService from '../data/DatabaseService';
import GroupService from '../data/groups/GroupService';
import UserService from '../data/users/UserService';
import GroupMemberMoveDropdown from './GroupMemberMoveDropdown';

function GroupMemberTable(props) {

    const [currentGroupMembers, setCurrentGroupMembers] = useState([]);
    const [refreshFlag, refresh] = useState(false);
    
    let moves = [];

    async function refreshCurrentGroupMembers() {
        if (!props.selectedGroup) return;
        
        console.log("refreshing group members");
        let listRequest = await UserService.ListAllUsersInGroup(props.selectedGroup.value);
        if (!listRequest.data || !listRequest.data.listUsers) return;
        let members = listRequest.data.listUsers.items;
        console.log(members);
        setCurrentGroupMembers(members);
        
    };
    useEffect(() => {
        refreshCurrentGroupMembers();
        
    }, [props.selectedGroup, props.allGroups, refreshFlag]);


    const onClickMoveUsers = async () => {
        // get movements
        console.log(moves);
        let databaseService = new DatabaseService();
        for (const m in moves) {
            console.log(moves[m]);
            let move = moves[m];
            if (move.from != move.to) {
                console.log("moving member " + move.name + " from " + move.from + " to " + move.to);
                // get user
                let gameId = await UserService.GetAttributeFromCurrentUser("custom:gameId");
                console.log(gameId);
                let user = await databaseService.GetUser({name: move.name, gameId: gameId});
                await GroupService.MoveUserToGroup(user.data.getUser, move.to);
            }
        }
        refresh();
    }

    const addMovement = (e, {value}, member) => {
        moves[member.name] = {
            name: member.name,
            from: member.groupId,
            to: value
        };
    }

    return <Table celled>
        <Table.Header>
            <Table.Row>
                <Table.HeaderCell>User</Table.HeaderCell>
                <Table.HeaderCell>Move To</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {currentGroupMembers.map((m) => (
            <Table.Row key={m.id}>
                <Table.Cell>{m.name}</Table.Cell>
                <Table.Cell><GroupMemberMoveDropdown onChange={addMovement} member={m} /></Table.Cell>
            </Table.Row>
            ))}
            <Table.Row>
                <Table.Cell><Button basic onClick={onClickMoveUsers}>Move Users</Button></Table.Cell>
            </Table.Row>
        </Table.Body>
    </Table>;
}

export default GroupMemberTable;