const groupStrings = {
    "en-us": {
        "group-selection-dropdown": "Select Group",
        "please-enter-name": "Please enter a name.",
        "name-already-exists": "A group already exists with this name.",
        "group-created": "Group successfully created!",
        "group-deleted": "Group successfully deleted!",
        "group-deletion-error": "Group deletion failed. This group might not exist. Try refreshing the page.",
        "group-deletion-warning": "Any users in a deleted group will be moved to the Ungrouped group."
    }
};


export default groupStrings;
