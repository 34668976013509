import React, {useState, useEffect} from 'react';
import { Comment, Divider, Segment } from 'semantic-ui-react';
import CommentContainter from './Comment';
import ComposeCommentSection from './ComposeCommentSection';

const CommentSection = (props) => {
    const [comments, setComments] = useState(<div></div>);


    useEffect(() => {
        if (props.comments && props.comments.length > 0) {
            setComments(props.comments.map(x => 
                <CommentContainter key={x.id} timestamp={x.timestamp} contentId={props.contentId} commentId={x.id} author={x.author} commentText={x.text} replies={x.Replies || undefined} />
            ));
        } 
    }, [props]);
    return (
        <Segment basic>
            <div className="posted-comments">
                <Comment.Group>
                    {comments}
                </Comment.Group>
            </div>
            <Divider />
            <ComposeCommentSection contentId={props.contentId} />
        </Segment>
    );
}

export default CommentSection;