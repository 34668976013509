import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import CommentSection from './Comment/CommentSection';
import DatabaseService from '../data/DatabaseService';
import { Container } from 'semantic-ui-react';

function setReplies(comments) {
    for (let c of comments) {
        // find comment children
        let replies = comments.filter(x => x.parentCommentId && x.parentCommentId === c.id);
        c.Replies = replies;
    }
}

function ContentItemPage(props) {
    let { id } = useParams();
    let [ comments, setComments ] = useState([]);
    let item = [];

    useEffect(() => {
        const fetchComments = async () => {
            const databaseService = new DatabaseService();
            let listCommentsResult = await databaseService.ListComments();
            console.log(listCommentsResult);
            let allComments = listCommentsResult.data.listComments.items;

            if (allComments.length <= 0) return;
            
            allComments.sort((a, b) => {
                return a.timestamp > b.timestamp ? -1 : 1;
            });
            setReplies(allComments);
            setComments(allComments.filter((x) => {
                console.log(x);
                return (x.contentPage === id && !x.parentCommentId);
            }));
        }
        fetchComments();
    }, [id]);

    if (Array.isArray(props.content)) {
        item = props.content.find(x => x.props.id === id.toString());
    }
    else {
        item = <div>Item not found</div>;
    }

    console.log(props);

    return (
        <div className="email-page">
            <div>
                <h2>{item ? item.title : ""}</h2>
            </div>
            <Container className="email-main">
                {item}
            </Container>
        </div>
    );
    
}

export default ContentItemPage;