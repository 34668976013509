import awsconfig from '../../../aws-exports';   //'./../../../src/aws-exports';
import { Auth, API } from 'aws-amplify';
import DatabaseService from '../DatabaseService';
import EmailService from '../EmailService';
import GroupService from '../groups/GroupService';
import awsResources from '../../../aws-resources';

class UserService {

    static MAX_USERS = 40;

    static async GetAttributeFromCurrentUser(attributeName) {
        let currentUser = await Auth.currentAuthenticatedUser();
        let attributes = await Auth.userAttributes(currentUser);
        let atr = attributes.find(a => a.Name == attributeName);
        return atr ? atr.getValue() : null;
    }

    static ListAllUsers() {
        return new Promise(async (resolve, reject) => {
            let gameId = await this.GetAttributeFromCurrentUser("custom:gameId");
            if (gameId) {
                console.log("getting users in the same game");
                resolve(await this.ListAllUsersInSameGameAsUser(gameId));
                return;
            }
            else {
                // If admin, create a new game. If not, we have a problem. Log an error...?
                resolve([]);
            }
        });
    }

    static async ListAllUsersInGroup(groupId) {
        return new Promise(async (resolve, reject) => {
            let dbService = new DatabaseService();
            let listUsersInGroup = await dbService.ListUsersInGame({groupId: {eq: groupId}});
            resolve(listUsersInGroup);
            return;
        });
    }

    static async ListAllUsersInSameGameAsUser(gameId) {
        console.log("listing all users in game " + gameId);
        let dbService = new DatabaseService();

        // Get users in game
        let usersInGame = await dbService.ListUsersInGame({gameId: {eq: gameId}});
        return usersInGame.data.listUsers.items;
    }

    // Cognito groups, not game groups
    static async ListGroupsForUser(username) {
        const credentials = await Auth.currentCredentials();
        let AWS = require("aws-sdk");
        console.log(username);
        console.log(credentials);
        const cognitoIdentityServiceProvider = 
                    new AWS.CognitoIdentityServiceProvider({credentials: Auth.essentialCredentials(credentials), region: "us-east-1"});
        return cognitoIdentityServiceProvider.adminListGroupsForUser({
            Username: username,
            UserPoolId: awsconfig['aws_user_pools_id']
        }).promise();
    }

    static async GetUser(username) {
        console.log(username);
        const credentials = await Auth.currentCredentials();
        let AWS = require("aws-sdk");
        const cognitoIdentityServiceProvider = 
                    new AWS.CognitoIdentityServiceProvider({credentials: Auth.essentialCredentials(credentials)});
        const params = {
            Username: username,
            UserPoolId: awsconfig['aws_user_pools_id']
        };
        return cognitoIdentityServiceProvider.adminGetUser(params).promise();
    }

    static RemoveUserFromGroup(username, group) {
        return new Promise((resolve, reject) => {
            if (!group) resolve("The user " + username + " was not in  the group " + group + " or the group name is empty");
            let AWS = require("aws-sdk");
            Auth.currentCredentials()
            .then(credentials => {
                const cognitoIdentityServiceProvider = 
                    new AWS.CognitoIdentityServiceProvider({credentials: Auth.essentialCredentials(credentials)});
                let params = {
                    GroupName: group, /* required */
                    UserPoolId: awsResources["dev-userPoolId"], /* required */
                    Username:  username
                };

                resolve(cognitoIdentityServiceProvider.adminRemoveUserFromGroup(params).promise());
            });
        });
    }

    static AddUserToGroup(username, group) {
        return new Promise((resolve, reject) => {
            let AWS = require("aws-sdk");
            AWS.config.update({
                region: awsconfig["aws_appsync_region"]
              });
            Auth.currentCredentials()
            .then(credentials => {
                const cognitoIdentityServiceProvider = 
                    new AWS.CognitoIdentityServiceProvider({credentials: Auth.essentialCredentials(credentials)});
                let params = {
                    GroupName: group, /* required */
                    UserPoolId: awsResources["dev-userPoolId"], /* required */
                    Username:  username
                };

                resolve(cognitoIdentityServiceProvider.adminAddUserToGroup(params).promise());
            });
        });
    }

    static async AddUserToDb(email, cognitoId, group) {
        console.log("Adding this user to the db: ");
        console.log(email);
        let dbService = new DatabaseService();
        
        // Get the ungrouped group
        let ungroupedId = await GroupService.GetUngroupedGroupId();

        let userInput = {};
        let gameId = await this.GetAttributeFromCurrentUser("custom:gameId");
        userInput.gameId = gameId;
        userInput.groupId = ungroupedId;
        userInput.role = group;
        userInput.name = email;
        userInput.cognitoId = cognitoId;
        console.log("AddUserToDb: userInput...");
        console.log(userInput);
        await dbService.CreateUser(userInput);
    }

    static async UserExists(username) {
        try {
            let getUser = await this.GetUser(username);
            console.log(getUser)
        }
        catch(error) {
            if (error.code == "UserNotFoundException") {
                return false;
            }
            else {
                console.error(error.code);   
            }
        }
        return true;
    }

    static async CreateNewUsers(users, group) {
        return new Promise(async (resolve, reject) =>{
            // Users are limited
            var currentUsers = await this.ListAllUsers();
            if (currentUsers.length + users.length > this.MAX_USERS) {
                reject("You cannot have more than 40 users.");
                return;
            }

            let userCreationPromises = users.map(async (user) => {
                // Can't add a user if they are already in another Game
                // We know they are in another game if they are in the user pool
                var userExists = await this.UserExists(user.email);
                console.log(userExists);
                if (userExists) {
                    throw {type: "UserAlreadyExists", text: "User " + user.email + " already exists in this game or another instance of this game."};
                }

                let params = {
                    UserPoolId: awsResources["dev-userPoolId"],
                    Username: user.email,
                    DesiredDeliveryMediums: ["EMAIL"],
                    UserAttributes: [
                        {
                            Name: "email",
                            Value: user.email
                        }
                    ]
                };
                
                let AWS = require("aws-sdk");
                let creds = await Auth.currentCredentials()
                const cognitoIdentityServiceProvider = 
                        new AWS.CognitoIdentityServiceProvider({credentials: Auth.essentialCredentials(creds)});
                let result = await cognitoIdentityServiceProvider.adminCreateUser(params).promise();

                UserService.AddUserToGroup(result.User.Username, group);
                let gameId = await this.GetAttributeFromCurrentUser("custom:gameId");
                
                var attributesParams = {
                    UserAttributes: [
                        {
                            Name: 'custom:gameId',
                            Value: gameId
                        },
                        {
                            Name: "nickname",
                            Value: user.nickname
                        }
                    ],
                    UserPoolId: awsconfig['aws_user_pools_id'],
                    Username: result.User.Username
                };
                await cognitoIdentityServiceProvider.adminUpdateUserAttributes(attributesParams).promise()
                .catch((reason) => {
                    console.error(reason);
                    reject(reason);
                    return;
                });

                let newUserEmailAddress = result.User.Attributes.find(a => a.Name == "email").Value;
                this.AddUserToDb(newUserEmailAddress, result.User.Username, group);
            });
            await Promise.all(userCreationPromises)
            .then(async (data) => {
                resolve({text: "users added", type: "success"});

                let emailAddresses = users.map((user) => user.email);
                if (!emailAddresses || emailAddresses.length <= 0) return;

                let gameId = await this.GetAttributeFromCurrentUser("custom:gameId");
                EmailService.SendIntroEmailToUsers(gameId, emailAddresses);
            })
            .catch((err) => {
                // maybe filter by type and only reject on certain exceptions?
                if (err.type == "UserAlreadyExists") {
                    reject({text: err.text, type: "error"});
                }
                console.log(err);
            });
        });
        
    }

    static async DeleteCurrentUser() {
       // Get this user's group
       
       // Get the users in this user's group

       // Are there other admins in this group?

       // Yes? Great. Just delete this account
       // ...then log out

       // No? Delete all of the other accounts first
    }

    static async EnableDisableUsers(users) {
        console.log(users);
        let userPromises = users.map((user) => {
            if (user.WasEnabled) {
                console.log("Disabling user " + user.Username);
                return this.DisableUser(user.Username);
            }
            else {
                console.log("Enabling user " + user.Username);
                return this.EnableUser(user.Username);
            }
        });

        return Promise.all(userPromises);
    }

    static async EnableUser(username) {
        let params = {
            UserPoolId: awsResources["dev-userPoolId"],
            Username: username
        };
        let AWS = require("aws-sdk");
        Auth.currentCredentials()
        .then(credentials => {
            let cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider({credentials: credentials});
            return  cognitoIdentityServiceProvider.adminEnableUser(params).promise();
        });
    }
    
    static async DisableUser(username) {
        let params = {
            UserPoolId: awsResources["dev-userPoolId"],
            Username: username
        };
        let AWS = require("aws-sdk");
        await Auth.currentCredentials()
        .then(credentials => {
            let cognitoIdentityServiceProvider = new AWS.CognitoIdentityServiceProvider({credentials: credentials});
            return  cognitoIdentityServiceProvider.adminDisableUser(params).promise();
        });
    }

}
export default UserService;