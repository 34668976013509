import { deleteSchedule } from '../../../graphql/mutations';
import ContentAvailabilityService from '../ContentAvailabilityService';
import DatabaseService from '../DatabaseService';
import UserService from '../users/UserService';
import { API, graphqlOperation } from 'aws-amplify';

// The "groups" in this class are the user-created kind, not the Cognito kind
class GroupService {

    static ListAllGroups() {
        return new Promise(async (resolve, reject) => {
            let databaseService = new DatabaseService();
            let gameId = await UserService.GetAttributeFromCurrentUser("custom:gameId");
            let groups = await databaseService.ListGroups({gameId: gameId});
            resolve(groups);
        });
    }
    
    static CreateGroup(name) {
        return new Promise(async (resolve, reject) => {

            let allGroups = await this.ListAllGroups();
            let match = allGroups.data.listGroups.items.find(g => g.name == name);
            if (match) {
                reject("A group already exists with this name");
                return;
            }

            let databaseService = new DatabaseService();
            let gameId = await UserService.GetAttributeFromCurrentUser("custom:gameId");
            let createGroupInput = {
                gameId: gameId,
                name: name
            };
            let createGroup = await databaseService.CreateGroup(createGroupInput);
            
            //create a corresponding schedule
            var groupId = createGroup.data.createGroup.id;
            var contentService = new ContentAvailabilityService();
            contentService.createOrUpdateContentSchedule(null, null, groupId);//create a default schedule

            resolve(createGroup);
        });
    }

    static DeleteGroup(groupId) {
        return new Promise(async (resolve, reject) => {

            let ungroupedId =  await this.GetUngroupedGroupId();
            this.MoveUsersFromGroupToUngrouped(groupId, ungroupedId);

            let databaseService = new DatabaseService();
            let gameId = await UserService.GetAttributeFromCurrentUser("custom:gameId");
            let deleteGroup = databaseService.DeleteGroup({gameId: gameId, id: groupId});
            //delete corresponding schedule
            var contentService = new ContentAvailabilityService();
            var sched = await contentService.getContentSchedule(gameId, groupId);
            API.graphql(graphqlOperation(deleteSchedule, {input: {gameId: gameId, id: sched.id}})).catch((error) => console.log(error));

            resolve(deleteGroup);
        });
    }

    static async GetUngroupedGroupId() {
        let allGroupsRequest = await this.ListAllGroups();
        let allGroups = allGroupsRequest.data.listGroups.items;
        console.log("GetUngroupedGroupId: allGroups...");
        console.log(allGroups);
        let ungrouped = allGroups.filter(g => g.name == "Ungrouped")[0];
        return ungrouped.id;
    }

    static async MoveUserToGroup(user, newGroupId) {
        let databaseService = new DatabaseService();
        user.groupId = newGroupId;
        delete user.createdAt;
        delete user.updatedAt;
        await databaseService.UpdateUser(user);
    }

    static async MoveUsersFromGroupToUngrouped(oldGroupId, newGroupId) {
        let allUsers = await UserService.ListAllUsers();
        console.log(allUsers);
        let usersInOldGroup = allUsers.filter(u => u.groupId == oldGroupId);
        usersInOldGroup.forEach(user => {
            this.MoveUserToGroup(user, newGroupId);
        });
    }

}
export default GroupService;