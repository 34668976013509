import React, { useState } from 'react';
import { Label, Header, Segment, Form, Icon, Divider, Dropdown } from 'semantic-ui-react';

function CreateUsersSection(props) {

    const [nickname, setNickname] = useState("");
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("");

    return <Segment className="user-management-section no-border">
            <Header as="h2">New User</Header>
            <Divider />
            <Form id="create-user-form">
                <Form.Input label="Nickname" type="text" onChange={(e, newNickName) => setNickname(newNickName.value)} />
                <Form.Input label="Email Address" type="text" onChange={(e, newEmail) => setEmail(newEmail.value)} />
                <Form.Field>
                    <label>Role</label>
                    <Dropdown placeholder="Select Role" labeled fluid selection
                        onChange={(e, selected) => setRole(selected.value)} options={[
                        {
                            key: 'Student',
                            text: 'Student',
                            value: 'Player'
                        },
                        {
                            key: 'Teacher',
                            text: 'Teacher',
                            value: 'Admin'
                        }
                    ]} />
                </Form.Field>
                <Form.Button content="Create User" onClick={() => {
                    props.createNewUsersFunction({
                        email: email,
                        nickname: nickname,
                        role: role
                    });
                    document.getElementById("create-user-form").reset();
                }}/>
            </Form>
    </Segment>
}

export default CreateUsersSection;