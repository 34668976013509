import React, {useState, useEffect} from 'react';
import ContentAvailabilityService from '../data/ContentAvailabilityService';
import ScheduleDisplay from './ScheduleDisplay';
import { Button, Divider, Segment } from 'semantic-ui-react';
import SQSMessagingService from './SQSMessagingService';
import SecureLS from 'secure-ls';
import { Auth } from 'aws-amplify';
import { Dropdown } from 'semantic-ui-react';
import groupStrings from '../data/groups/groupStrings';
import GroupService from '../data/groups/GroupService';

function SchedulingSection() {

    const contentAvailabilityService = new ContentAvailabilityService();

    const [schedule, setSchedule] = useState();
    const [scheduleDraft, setScheduleDraft] = useState(null);
    const [groups, setGroups] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [dirty, clean] = useState(false);

    useEffect(() => {
        console.log(groups);
        if (groups == null) {
            refreshCurrentGroups();
        }
        loadSchedule();
    }, [selectedGroup]);

    async function refreshCurrentGroups() {
        console.log("refreshing groups");
        await GroupService.ListAllGroups().then(async (result) => {
            if (!result.data || !result.data.listGroups) return;
            let listedGroups = result.data.listGroups.items.map(function(g) {
                return {
                    key: g.id,
                    text: g.name,
                    value: g.id
                };
            });
            listedGroups.sort((a, b) => {return a.text > b.text ? 1 : -1 });
            setGroups(listedGroups);
        });
    };

    const loadSchedule = async () => {
        console.log("loading schedule");
        let user = await Auth.currentAuthenticatedUser();
        let attributes = await Auth.userAttributes(user);
        let gameId = attributes.find(a => a.Name == "custom:gameId");
        if (!gameId) return;
        console.log(gameId);
        console.log(selectedGroup);
        const scheduleResult = await contentAvailabilityService.getContentSchedule(gameId.Value, selectedGroup);
        // save locally in a cookie to reduce database hits
        //localStorage.setItem("schedule", JSON.stringify(result));
        let ls = new SecureLS();
        ls.set("schedule", JSON.stringify(scheduleResult));
        console.log(scheduleResult);
        setSchedule(scheduleResult);
    };
    
    const saveSchedule = async() => {
        console.log(scheduleDraft);
        console.log("CREATING A NEW SCHEDULE!!!")
        if (scheduleDraft != null)
        {
            let saveResult = await contentAvailabilityService.createOrUpdateContentSchedule(scheduleDraft, schedule.id, selectedGroup);
            console.log(saveResult);
            await loadSchedule();
        }
        await SQSMessagingService.sendMessage("schedule updated");
    };

    const updateScheduleDraft = (newDraftValue) => {
        console.log("updated schedule draft");
        setScheduleDraft(newDraftValue);
        console.log(JSON.parse(newDraftValue));
    }

    const onSelectGroup = (e, {value}) => {

        let group = groups.find(x => x.value == value);
        console.log(group);
        if (!group) return;
        setSelectedGroup(group.value);
    }

    let scheduleSection = <div></div>;
    if (selectedGroup != null) {
        scheduleSection = <Segment>
            <div>
                <ScheduleDisplay onChange={updateScheduleDraft} scheduleObject={schedule != null ? schedule.json : null} />
            </div>
            <div>
                <Button basic onClick={saveSchedule}>Save</Button>
                <Button basic onClick={loadSchedule}>Reload</Button>
            </div>
        </Segment>;
    }
    return (
        <Segment className="scheduling-section">
            <h2>Select Group</h2>
            <Dropdown fluid selection placeholder={groupStrings["en-us"]['group-selection-dropdown']}
                    options={groups ?? []} onChange={onSelectGroup} value={selectedGroup ? selectedGroup.value : null}/>
                    <Divider />
            {scheduleSection}
        </Segment>
    );
}

export default SchedulingSection;