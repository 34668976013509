import React, {Component, useState} from 'react';
import { Button, Container, Segment } from 'semantic-ui-react';
const ImageTemplate = (props) =>  {
    return (
        <div>
            <video width="100%" controls>
            <source src={props.body} type="video/mp4"/>
            </video>
        </div>
    );
}

export default ImageTemplate;