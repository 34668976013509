import React, {Component, useState} from 'react';
import { Button, Container, Segment } from 'semantic-ui-react';
const DocumentTemplate = (props) =>  {
    return (
        <div>
            <object width="100%" height="540" data={props.body} type="application/pdf"/>
        </div>
    );
}

export default DocumentTemplate;