import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { Grid, Segment } from 'semantic-ui-react';
import elephant from './assets/elephant.jpg';

const Episode0 = (props) => {
    {
        return (
            <div>
                <Segment className="email-header">
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <h2>{Episode0.title}</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <p>From: {Episode0.sender}</p>
                            </Grid.Column>
                            <Grid.Column>
                                <p>DATETIME</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment className="email-body">
                    <p>Putting some text here to see how it displays...</p>
                    <p>Here is a <Link to="/documents/doc0">link</Link> to a doc.</p>
                    <img src={elephant} />
                </Segment>
            </div>
        );
    }
};
Episode0.id = "ep0";
Episode0.title = "Elephant Sighted!";
Episode0.isAdmin = false;
Episode0.sender = "Sender Name";
Episode0.emailPreview = "This is a preview.";

export default Episode0;