/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEpisode = /* GraphQL */ `
  mutation CreateEpisode(
    $input: CreateEpisodeInput!
    $condition: ModelEpisodeConditionInput
  ) {
    createEpisode(input: $input, condition: $condition) {
      id
      index
      name
      contentPages {
        items {
          id
          title
          s3ObjectName
          sent
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateEpisode = /* GraphQL */ `
  mutation UpdateEpisode(
    $input: UpdateEpisodeInput!
    $condition: ModelEpisodeConditionInput
  ) {
    updateEpisode(input: $input, condition: $condition) {
      id
      index
      name
      contentPages {
        items {
          id
          title
          s3ObjectName
          sent
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteEpisode = /* GraphQL */ `
  mutation DeleteEpisode(
    $input: DeleteEpisodeInput!
    $condition: ModelEpisodeConditionInput
  ) {
    deleteEpisode(input: $input, condition: $condition) {
      id
      index
      name
      contentPages {
        items {
          id
          title
          s3ObjectName
          sent
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createContentPage = /* GraphQL */ `
  mutation CreateContentPage(
    $input: CreateContentPageInput!
    $condition: ModelContentPageConditionInput
  ) {
    createContentPage(input: $input, condition: $condition) {
      id
      title
      s3ObjectName
      sent
      episode {
        id
        index
        name
        contentPages {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateContentPage = /* GraphQL */ `
  mutation UpdateContentPage(
    $input: UpdateContentPageInput!
    $condition: ModelContentPageConditionInput
  ) {
    updateContentPage(input: $input, condition: $condition) {
      id
      title
      s3ObjectName
      sent
      episode {
        id
        index
        name
        contentPages {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteContentPage = /* GraphQL */ `
  mutation DeleteContentPage(
    $input: DeleteContentPageInput!
    $condition: ModelContentPageConditionInput
  ) {
    deleteContentPage(input: $input, condition: $condition) {
      id
      title
      s3ObjectName
      sent
      episode {
        id
        index
        name
        contentPages {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      parentCommentId
      text
      author
      timestamp
      contentPage
      createdAt
      updatedAt
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      parentCommentId
      text
      author
      timestamp
      contentPage
      createdAt
      updatedAt
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      parentCommentId
      text
      author
      timestamp
      contentPage
      createdAt
      updatedAt
    }
  }
`;
export const createScheduledSendContent = /* GraphQL */ `
  mutation CreateScheduledSendContent(
    $input: CreateScheduledSendContentInput!
    $condition: ModelScheduledSendContentConditionInput
  ) {
    createScheduledSendContent(input: $input, condition: $condition) {
      id
      s3ObjectName
      createdAt
      updatedAt
    }
  }
`;
export const updateScheduledSendContent = /* GraphQL */ `
  mutation UpdateScheduledSendContent(
    $input: UpdateScheduledSendContentInput!
    $condition: ModelScheduledSendContentConditionInput
  ) {
    updateScheduledSendContent(input: $input, condition: $condition) {
      id
      s3ObjectName
      createdAt
      updatedAt
    }
  }
`;
export const deleteScheduledSendContent = /* GraphQL */ `
  mutation DeleteScheduledSendContent(
    $input: DeleteScheduledSendContentInput!
    $condition: ModelScheduledSendContentConditionInput
  ) {
    deleteScheduledSendContent(input: $input, condition: $condition) {
      id
      s3ObjectName
      createdAt
      updatedAt
    }
  }
`;
export const createContentUpdateLog = /* GraphQL */ `
  mutation CreateContentUpdateLog(
    $input: CreateContentUpdateLogInput!
    $condition: ModelContentUpdateLogConditionInput
  ) {
    createContentUpdateLog(input: $input, condition: $condition) {
      id
      content {
        id
        title
        s3ObjectName
        sent
        episode {
          id
          index
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      LastUpdated
      createdAt
      updatedAt
    }
  }
`;
export const updateContentUpdateLog = /* GraphQL */ `
  mutation UpdateContentUpdateLog(
    $input: UpdateContentUpdateLogInput!
    $condition: ModelContentUpdateLogConditionInput
  ) {
    updateContentUpdateLog(input: $input, condition: $condition) {
      id
      content {
        id
        title
        s3ObjectName
        sent
        episode {
          id
          index
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      LastUpdated
      createdAt
      updatedAt
    }
  }
`;
export const deleteContentUpdateLog = /* GraphQL */ `
  mutation DeleteContentUpdateLog(
    $input: DeleteContentUpdateLogInput!
    $condition: ModelContentUpdateLogConditionInput
  ) {
    deleteContentUpdateLog(input: $input, condition: $condition) {
      id
      content {
        id
        title
        s3ObjectName
        sent
        episode {
          id
          index
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      LastUpdated
      createdAt
      updatedAt
    }
  }
`;
export const createGame = /* GraphQL */ `
  mutation CreateGame(
    $input: CreateGameInput!
    $condition: ModelGameConditionInput
  ) {
    createGame(input: $input, condition: $condition) {
      id
      invitationEmailTemplate
      createdAt
      updatedAt
    }
  }
`;
export const updateGame = /* GraphQL */ `
  mutation UpdateGame(
    $input: UpdateGameInput!
    $condition: ModelGameConditionInput
  ) {
    updateGame(input: $input, condition: $condition) {
      id
      invitationEmailTemplate
      createdAt
      updatedAt
    }
  }
`;
export const deleteGame = /* GraphQL */ `
  mutation DeleteGame(
    $input: DeleteGameInput!
    $condition: ModelGameConditionInput
  ) {
    deleteGame(input: $input, condition: $condition) {
      id
      invitationEmailTemplate
      createdAt
      updatedAt
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup(
    $input: CreateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    createGroup(input: $input, condition: $condition) {
      id
      gameId
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup(
    $input: UpdateGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    updateGroup(input: $input, condition: $condition) {
      id
      gameId
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup(
    $input: DeleteGroupInput!
    $condition: ModelGroupConditionInput
  ) {
    deleteGroup(input: $input, condition: $condition) {
      id
      gameId
      name
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      cognitoId
      gameId
      groupId
      role
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      cognitoId
      gameId
      groupId
      role
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      cognitoId
      gameId
      groupId
      role
      name
      createdAt
      updatedAt
    }
  }
`;
export const createSchedule = /* GraphQL */ `
  mutation CreateSchedule(
    $input: CreateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    createSchedule(input: $input, condition: $condition) {
      id
      gameId
      groupId
      json
      lastChecked
      createdAt
      updatedAt
    }
  }
`;
export const updateSchedule = /* GraphQL */ `
  mutation UpdateSchedule(
    $input: UpdateScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    updateSchedule(input: $input, condition: $condition) {
      id
      gameId
      groupId
      json
      lastChecked
      createdAt
      updatedAt
    }
  }
`;
export const deleteSchedule = /* GraphQL */ `
  mutation DeleteSchedule(
    $input: DeleteScheduleInput!
    $condition: ModelScheduleConditionInput
  ) {
    deleteSchedule(input: $input, condition: $condition) {
      id
      gameId
      groupId
      json
      lastChecked
      createdAt
      updatedAt
    }
  }
`;
export const createFcmToken = /* GraphQL */ `
  mutation CreateFcmToken(
    $input: CreateFCMTokenInput!
    $condition: ModelFCMTokenConditionInput
  ) {
    createFCMToken(input: $input, condition: $condition) {
      id
      userId
      token
      subscribed
      createdAt
      updatedAt
    }
  }
`;
export const updateFcmToken = /* GraphQL */ `
  mutation UpdateFcmToken(
    $input: UpdateFCMTokenInput!
    $condition: ModelFCMTokenConditionInput
  ) {
    updateFCMToken(input: $input, condition: $condition) {
      id
      userId
      token
      subscribed
      createdAt
      updatedAt
    }
  }
`;
export const deleteFcmToken = /* GraphQL */ `
  mutation DeleteFcmToken(
    $input: DeleteFCMTokenInput!
    $condition: ModelFCMTokenConditionInput
  ) {
    deleteFCMToken(input: $input, condition: $condition) {
      id
      userId
      token
      subscribed
      createdAt
      updatedAt
    }
  }
`;
export const createPlayerData = /* GraphQL */ `
  mutation CreatePlayerData(
    $input: CreatePlayerDataInput!
    $condition: ModelPlayerDataConditionInput
  ) {
    createPlayerData(input: $input, condition: $condition) {
      gameId
      userName
      json
      createdAt
      updatedAt
    }
  }
`;
export const updatePlayerData = /* GraphQL */ `
  mutation UpdatePlayerData(
    $input: UpdatePlayerDataInput!
    $condition: ModelPlayerDataConditionInput
  ) {
    updatePlayerData(input: $input, condition: $condition) {
      gameId
      userName
      json
      createdAt
      updatedAt
    }
  }
`;
export const deletePlayerData = /* GraphQL */ `
  mutation DeletePlayerData(
    $input: DeletePlayerDataInput!
    $condition: ModelPlayerDataConditionInput
  ) {
    deletePlayerData(input: $input, condition: $condition) {
      gameId
      userName
      json
      createdAt
      updatedAt
    }
  }
`;
