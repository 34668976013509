import React from 'react';
import { List } from 'semantic-ui-react';
import InboxEmailItem from './InboxEmailItem';

const InboxEmailItemList = (props) => {
    console.log(props.content);
    return (
        <div className="content-section">
            <List>
                {
                    props.content.length === undefined ? <div></div> : 
                    props.content.map(contentItem => 
                        <List.Item key={contentItem.props.id} className="email-list-item">
                            <InboxEmailItem key={contentItem.props.id} item={contentItem} isAdmin={contentItem.props.isAdmin} 
                                sent={contentItem.sent} title={contentItem.props.title} id={contentItem.props.id} 
                                sender={contentItem.props.sender} preview={contentItem.props.emailPreview} />
                        </List.Item>)
                }
            </List>
        </div>
    )
}

export default InboxEmailItemList;
