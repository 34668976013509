import React, {useState} from 'react';
import DateTimeContainer from './DateTimeContainer';
import { Button, Checkbox, Divider, Dropdown, Form, Input, Label, TextArea } from 'semantic-ui-react';

function EpisodeDisplay (props) {
    //this.setReleaseTimeToNow = this.setReleaseTimeToNow.bind(this);
    //this.sendUpdatedEpisodeTimingToSchedule = this.sendUpdatedEpisodeTimingToSchedule.bind(this);

    const sendUpdatedEpisodeTimingToSchedule = (date, episode) => {
        props.onChange({date, episode});
    }

    let episodeLoaded = props.episodeObject != null;
    let display = <div></div>;


    if (episodeLoaded) {
        display = <div className="episode-display">
            <div>Name: {props.episodeObject["name"]}</div>
            <div>Release Time: <DateTimeContainer 
                onChange={
                    (date) => sendUpdatedEpisodeTimingToSchedule(date, props.episodeObject)
                } 
                value={new Date(props.episodeObject["scheduledTime"])} />
            </div>
        </div>;
    }
    return display;

}

export default EpisodeDisplay;