import React, {useEffect, useState} from 'react';
import { Form, Segment, Header } from 'semantic-ui-react';
import EnableDisableUserOptions from "./EnableDisableUserOptions";

function EnableDisableUsersSection(props) {


    return <Segment className="user-management-section no-border">
        <Header as="h2">Enable and Disable Users</Header>
        <Form>
            {props.users.map((user) => <EnableDisableUserOptions key={user.Username + user.Enabled} onToggle={props.onToggle} user={user} />)}
            <Form.Group>
                <Form.Button basic content="Perform Enable/Disable" onClick={props.enableDisableUsersFunction} />
            </Form.Group>
        </Form>
    </Segment>
}

export default EnableDisableUsersSection;