import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import UserService from '../../../data/users/UserService';
import { Rating, Image, Form, Grid, Segment, Checkbox, TextArea, Input, Label, Button } from 'semantic-ui-react';
import hammock from './assets/hammock.jpg';
import juggling from './assets/juggling.jpg';
import dumpsterFire from './assets/dumpsterFire.jpg';
import DatabaseService from '../../../data/DatabaseService';
import { isCompositeComponent } from 'react-dom/test-utils';

const ReclectionForm1 = (props) => {
    const [question1, setQuestion1] = useState(null);
    const [question2, setQuestion2] = useState([]);
    const [question3, setQuestion3] = useState([]);
    const [question4, setQuestion4] = useState("");
    const [question5, setQuestion5] = useState("1");
    const [question5Label, setQuestion5Label] = useState("Really easy");
    
    const [submittedData, setSubmittedData] = useState(null);
    const getPlayerData = async () => {
        let databaseService = new DatabaseService();
        let gameId = await UserService.GetAttributeFromCurrentUser("custom:gameId");
        let user = await Auth.currentAuthenticatedUser();
        let userName = user.attributes["email"];
        let playerDataQuery = await databaseService.GetPlayerData({
            gameId: gameId,
            userName: userName
        });
        return playerDataQuery.data.getPlayerData;
    };

    const setAnswers = (data) => {
        console.log(data);
        setQuestion1(data.ReflectionForm1.answers[0]);
        setQuestion2(data.ReflectionForm1.answers[1]);
        setQuestion3(data.ReflectionForm1.answers[2]);
        setQuestion4(data.ReflectionForm1.answers[3]);
        setQuestion5(data.ReflectionForm1.answers[4]);
        setQuestion5Label(question5ToLabelText(data.ReflectionForm1.answers[4]));
    }

    useEffect(() => {
        getPlayerData().then((data) => {
            if (data == null) {
                return;
            }
            let parsed = JSON.parse(data.json);
            setSubmittedData(parsed);
            setAnswers(parsed)
        });
    }, []);

    const question5ToLabelText = (q5) => {
        console.log(q5);
        switch (q5) {
            case "1":
                return "Really easy";
            case "2":
                return "Easy";
            case "3":
                return "So-so";
            case "4":
                return "Hard";
            case "5":
                return "Very hard";
            default:
                return "?";
        }
    }

    const question5SliderOnChange = (e, { value }) => {
        setQuestion5(value);
        setQuestion5Label(question5ToLabelText(value));
    }

    const question2and3CheckboxOnChange = (e, { questionindex, name, checked }) => {
        if (submittedData != null) return;
        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!");
        if (checked) {
            if (questionindex == 2) {
                setQuestion2(question2.concat([name]));
            }
            else {
                setQuestion3(question3.concat([name]));
            }
        }
        else {
            if (questionindex == 2) {
                let newQ2 = question2.filter((x) => x != name);
                setQuestion2(newQ2);
            }
            else {
                let newQ3 = question3.filter((x) => x != name);
                setQuestion3(newQ3);
            }
        }
    };

    const question4OnChange = (e, {value}) => {
        setQuestion4(value);
    }

    const createPlayerDataObject = () => {
        return {
            "ReflectionForm1": {
                "answers": [
                    question1,
                    question2,
                    question3,
                    question4,
                    question5
                ]
            }
        };
    };

    const formSubmit = async () => {
        let databaseService = new DatabaseService();
        let gameId = await UserService.GetAttributeFromCurrentUser("custom:gameId");
        let user = await Auth.currentAuthenticatedUser();
        let userName = user.attributes["email"];
        let playerData = await getPlayerData();
        
        let input = {
            gameId: gameId,
            userName: userName,
            json: JSON.stringify(createPlayerDataObject())
        };

        if (playerData == null)
        {
            await databaseService.CreatePlayerData(input);
        }
        else
        {
            await databaseService.UpdatePlayerData(input);
        }
        setSubmittedData(input.json);
    };

    const question1ImageClick = (e) => {
        if (submittedData != null) return;
        setQuestion1(e.currentTarget.name);
    }

    return (
        <div>
            <Segment className="email-header">
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column>
                            <h2>{ReclectionForm1.title}</h2>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <p>From: {ReclectionForm1.sender}</p>
                        </Grid.Column>
                        <Grid.Column>
                            <p>DATETIME</p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
            <Segment className="email-body">
                <Form onSubmit={formSubmit}>
                    <p>
                        Hey there, Mission Control.  
                        Word is out there that things might not be running 
                        so smoothly behind the scenes at Skylight.  
                        Inquiring minds want to know more...but they also 
                        don’t want to get caught by natural language processing 
                        data scrapers.  So if you think there’s something 
                        fishy here...maybe you could give us a hint below?</p>
                    <p>
                        We hear you had to build an emergency water filter 
                        for the Callisto astronauts. How'd it go? Select the image 
                        that best describes your experience.
                    </p>    
                    <Form.Field>
                        <Image.Group>
                            <Image width="200px" src={hammock} onClick={question1ImageClick} name="easy" alt="beach hammock during a sunset" disabled={question1 !== "easy"} />
                            <Image width="150px" src={juggling} onClick={question1ImageClick} name="medium" alt="a juggler juggling four balls at once" disabled={question1 !== "medium"} />
                            <Image width="200px" src={dumpsterFire} onClick={question1ImageClick} name="hard" alt="beach hammock during a sunset" disabled={question1 !== "hard"} />
                        </Image.Group>
                    </Form.Field>
                    <p>
                        Which materials were MOST useful in building the water filter?
                    </p>
                    <Form.Field disabled={submittedData != null}>
                        <Checkbox checked={question2.includes("cottonBalls")} questionindex={2} label="Cotton Balls" name="cottonBalls" onChange={question2and3CheckboxOnChange} />
                    </Form.Field>
                    <Form.Field disabled={submittedData != null}>
                        <Checkbox checked={question2.includes("ricePasta")} questionindex={2} label="Rice/Pasta" name="ricePasta" onChange={question2and3CheckboxOnChange} />
                    </Form.Field>
                    <Form.Field disabled={submittedData != null}>
                        <Checkbox checked={question2.includes("gravel")} questionindex={2} label="Gravel" name="gravel" onChange={question2and3CheckboxOnChange} />
                    </Form.Field>
                    <Form.Field disabled={submittedData != null}>
                        <Checkbox checked={question2.includes("sand")} questionindex={2} label="Sand" name="sand" onChange={question2and3CheckboxOnChange} />
                    </Form.Field>
                    <Form.Field disabled={submittedData != null}>
                        <Checkbox checked={question2.includes("zeolite")} questionindex={2} label="Zeolite" name="zeolite" onChange={question2and3CheckboxOnChange} />
                    </Form.Field>
                    <Form.Field disabled={submittedData != null}>
                        <Checkbox checked={question2.includes("charcoal")} questionindex={2} label="Activated Charcoal" name="charcoal" onChange={question2and3CheckboxOnChange} />
                    </Form.Field>

                    <p>
                        Which materials were LEAST useful in building the water filter?
                    </p>
                    <Form.Field disabled={submittedData != null}>
                        <Checkbox checked={question3.includes("cottonBalls")} questionindex={3} label="Cotton Balls" name="cottonBalls" onChange={question2and3CheckboxOnChange} />
                    </Form.Field>
                    <Form.Field disabled={submittedData != null}>
                        <Checkbox checked={question3.includes("ricePasta")} questionindex={3} label="Rice/Pasta" name="ricePasta" onChange={question2and3CheckboxOnChange} />
                    </Form.Field>
                    <Form.Field disabled={submittedData != null}>
                        <Checkbox checked={question3.includes("gravel")} questionindex={3} label="Gravel" name="gravel" onChange={question2and3CheckboxOnChange} />
                    </Form.Field>
                    <Form.Field disabled={submittedData != null}>
                        <Checkbox checked={question3.includes("sand")} questionindex={3} label="Sand" name="sand" onChange={question2and3CheckboxOnChange} />
                    </Form.Field>
                    <Form.Field disabled={submittedData != null}>
                        <Checkbox checked={question3.includes("zeolite")} questionindex={3} label="Zeolite" name="zeolite" onChange={question2and3CheckboxOnChange} />
                    </Form.Field>
                    <Form.Field disabled={submittedData != null}>
                        <Checkbox checked={question3.includes("charcoal")} questionindex={3} label="Activated Charcoal" name="charcoal" onChange={question2and3CheckboxOnChange} />
                    </Form.Field>

                    <p>
                        What’s something you wish you had when you were building the water filter?  
                        How would it have helped?
                    </p>
                    <Form.Field disabled={submittedData != null}>
                        <TextArea value={question4} onChange={question4OnChange} />
                    </Form.Field>

                    <p>
                        How difficult would you say it was to build the water filter?
                    </p>
                    <Form.Field disabled={submittedData != null}>
                        <Input type="range" 
                            value={question5}
                            min={1}
                            max={5}
                            onChange={question5SliderOnChange} />
                        <Label>
                            <Rating rating={question5} maxRating={5} />
                            {question5Label}
                        </Label>
                    </Form.Field>
                    <Button disabled={submittedData != null} type="submit">Send</Button>
                </Form>
            </Segment>
        </div>
    );
};
ReclectionForm1.id = "reflectionForm1";
ReclectionForm1.title = "Your Thoughts?";
ReclectionForm1.isAdmin = false;
ReclectionForm1.sender = "Sender Name";
ReclectionForm1.emailPreview = "Just wanted to pick your brain a bit...";

export default ReclectionForm1;