import React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Segment } from 'semantic-ui-react';

const EpisodePharosTest = (props) => {
    {
        return (
            <div>
                <Segment className="email-header">
                    <Grid columns={2}>
                        <Grid.Row>
                            <Grid.Column>
                                <h2>{EpisodePharosTest.title}</h2>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <p>From: {EpisodePharosTest.sender}</p>
                            </Grid.Column>
                            <Grid.Column>
                                <p>DATETIME</p>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
                <Segment className="email-body">
                    <p>Putting some text here to see how it displays...</p>
                    <p>Here is a <Link to="/tasks">link</Link> to the task page.</p>
                </Segment>
            </div>
        );
    }
};
EpisodePharosTest.id = "epPharosTest";
EpisodePharosTest.title = "The Pharos Memo";
EpisodePharosTest.isAdmin = false;
EpisodePharosTest.sender = "Sender Name";
EpisodePharosTest.emailPreview = "This is the Pharos Memo.";

export default EpisodePharosTest;