import React, { useState } from 'react';
import ComposeCommentReply from './ComposeCommentReply';
import CommentReplies from '../CommentReplies';
import IdentityService from '../../data/IdentityService';
import DatabaseService from '../../data/DatabaseService';
import CommentStrings from './CommentStrings';
import { Comment } from 'semantic-ui-react';
import ComposeCommentSection from './ComposeCommentSection';
import { generateFromString } from 'generate-avatar';

const deleteComment = async (repliesExist, contentId, commentId) => {

    // Only fully delete comments that have no replies
    if (!repliesExist) {
        let databaseService = new DatabaseService();
        let deleteCommentInput = {
            id: commentId
        };
        await databaseService.DeleteComment(deleteCommentInput);
        window.location.reload();
    }
    else {
        // If a comment has replies, replace the text with "Comment deleted",
        // so that the replies can persist.

        let databaseService = new DatabaseService();
        let updateCommentInput = {
            id: commentId,
            text: CommentStrings.comment_deleted["en-us"]
        };
        await databaseService.UpdateComment(updateCommentInput);
        window.location.reload();
    }

}

const getReplies = (replies, contentId) => {
    if (!replies) return undefined;
    return replies.map(x => <CommentContainter contentId={contentId} timestamp={x.timestamp} key={x.id} commentId={x.id} author={x.author} commentText={x.text} replies={x.Replies || undefined} />)
}

const CommentContainter = (props) => {
    let replies = getReplies(props.replies, props.contentId);

    let isAdmin = IdentityService.isInAnyGroups(["Admin", "Moderator"]);
    const onClickDelete = () => {
        deleteComment(replies.length > 0, props.contentId, props.commentId)
    }; 

    let deleteButton = isAdmin ? (
    <div className="comment-moderator-controls">
        <button onClick={onClickDelete}>Delete Comment</button>
    </div>) : '';

    const [composeSection, setComposeSection] = useState('');
        
    const discardClicked = (event) => {
        setComposeSection('');
    }
    const replyClicked = () => {
        console.log(props.parentCommentId);
        setComposeSection(<ComposeCommentSection onDiscardClicked={discardClicked} isReply={true} contentId={props.contentId} parentCommentId={props.commentId} />);
    }


    return (
        <div>
            <Comment>
                <Comment.Content>
                    <Comment.Avatar src={`data:image/svg+xml;utf8,${generateFromString(props.author)}`} />
                    <Comment.Author>{props.author}</Comment.Author>
                    <Comment.Metadata>
                        <div>{new Date(props.timestamp).toLocaleString()}</div>
                    </Comment.Metadata>
                    <Comment.Text>{props.commentText}</Comment.Text>
                    <Comment.Actions>
                        <Comment.Action onClick={replyClicked}>Reply</Comment.Action>
                        {isAdmin ? <Comment.Action onClick={onClickDelete}>Delete</Comment.Action> : <div></div>}
                    </Comment.Actions>
                </Comment.Content>
                <Comment.Group>
                    <div className="comment-replies">
                        <CommentReplies replies={replies} />
                    </div>
                </Comment.Group>
                <div className="compose-reply-text">
                    {composeSection}
                </div>
            </Comment>
        </div>
    );
}

export default CommentContainter;