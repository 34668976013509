import React, {Component} from 'react';
import { Grid, Segment } from 'semantic-ui-react';

const TaskTemplate = (props) => {
    {
        return (
            <div>
                <Segment textAlign="center">
                    <iframe src = {props.body} width="1500px" height="675px"></iframe>
                </Segment>
            </div>
        );
    }
};

export default TaskTemplate;