import React from 'react';
import EpisodeDisplay from './EpisodeDisplay';


const ScheduleDisplay = (props) => {
    const getEpisodes = (schedule) => {
        return schedule["schedule"]["episodes"];
    }

    const getDocuments = (schedule) => {
        return schedule["schedule"]["documents"];
    }

    const getTasks = (schedule) => {
        return schedule["schedule"]["tasks"];
    }

    const updateScheduleDraftEpisode = (contentData, onChange, scheduleObject) => { 
        let parsed = JSON.parse(scheduleObject);
        parsed.schedule.episodes.forEach((ep) => {
            if (ep.name === contentData.episode.name) {
                if (contentData.date) {
                    ep.scheduledTime = contentData.date.toString();
                }
                if (contentData.notificationSettings) {
                    ep.notification = contentData.notificationSettings;
                }
            }
        });

        parsed.schedule.documents.forEach((doc) => {
            if (doc.name === contentData.episode.name) {
                if (contentData.date) {
                    doc.scheduledTime = contentData.date.toString();
                }
                if (contentData.notificationSettings) {
                    doc.notification = contentData.notificationSettings;
                }
            }
        });

        parsed.schedule.tasks.forEach((task) => {
            if (task.name === contentData.episode.name) {
                if (contentData.date) {
                    task.scheduledTime = contentData.date.toString();
                }
                if (contentData.notificationSettings) {
                    task.notification = contentData.notificationSettings;
                }
            }
        });
        
        onChange(JSON.stringify(parsed));
    }

    const getEpisodeDisplays = (episodes) => {
        console.log("getEpisodeDisplays");
        let displays = new Array();
        episodes.forEach((episode, index) =>{
            console.log(episode);
            displays.push(<EpisodeDisplay onChange={(episode) => updateScheduleDraftEpisode(episode, props.onChange, props.scheduleObject)} key={index} episodeObject={episode}/>);
        });

        return displays;
    }

    let scheduleLoaded = props.scheduleObject != null;
    let display = <div></div>;
    
    if (scheduleLoaded) {
        let parsedJSON = JSON.parse(props.scheduleObject);
        
        let episodes = getEpisodes(parsedJSON);
        let episodeDisplays = getEpisodeDisplays(episodes);

        let documents = getDocuments(parsedJSON);
        let documentDisplays = getEpisodeDisplays(documents);

        let tasks = getTasks(parsedJSON);
        let taskDisplays = getEpisodeDisplays(tasks);

        display = <div className="schedule-display">
            <div>
                <h2>Schedule of Content</h2>
                <div>
                    {episodeDisplays}
                    {documentDisplays}
                    {taskDisplays}
                </div>
            </div>
        </div>;

        return display;
    }
    return <div></div>

}

export default ScheduleDisplay;