import React, { Component } from 'react';
import './styles.css';
import {FileIcon, FileMediaIcon, FileZipIcon, FileDirectoryIcon, PlayIcon, FileBinaryIcon} from '@primer/octicons-react';
import {
    Switch,
    Route,
    useRouteMatch,
    Link
} from "react-router-dom";

const FileGrid = (props) => {

    
    let selectedFile = "";

    const fileEvent = (e, file) => {        
        selectedFile = file;
        if(file){
            e.stopPropagation()

            if (props.onClickFile) {
                props.onClickFile(file)
            }
        }
    }

    fileEvent.bind(this);

    const iconRenderer = (type) =>{
        switch (type) {
            case undefined:
                    return(<FileIcon />)

            case 'exe':
                return(<FileBinaryIcon />)

            case 'folder':
                return(<FileDirectoryIcon />)

            case 'media':
                    return(<FileMediaIcon />) 

            case 'video':
                return(<PlayIcon />) 
            
            case 'zip':
                    return(<FileZipIcon />) 
        
            default:
                return(type)
        }
    }

    //<Link to={`${url}/${props.id}`}><ContentItem {...props} /></Link>
    let { path, url } = useRouteMatch();
    let files = props.files.map( item => {
        return (
            <Link key={item.link} to={`${url}/${item.link}`}>
                <div className="file-item"
                        onClick={e => fileEvent(e, item)}>
                    <div className="icon">
                        {iconRenderer(item.icon)}
                    </div>
                    <div className="file-detail">
                        <div className="filename">{item.filename}</div>
                        <div className="file-props">
                            <div className="filetime">{item.filedate}</div>
                            <div className="filesize">{item.filesize}</div>
                        </div>
                    </div>
                </div>
            </Link>
        )
    });
    return(
        <React.Fragment>
            <div className="file-grid" onClick={e => fileEvent(e, null)}>
                {files}
            </div>
        </React.Fragment>
    )
}

export default FileGrid;