import React, {useEffect, useState} from 'react';
import { Dropdown } from 'semantic-ui-react';
import groupStrings from '../data/groups/groupStrings';
import GroupService from '../data/groups/GroupService';

function GroupMemberMoveDropdown(props) {
    const [groups, setGroups] = useState([]);


    const getGroups = async () => {
        const groups = await GroupService.ListAllGroups();
        console.log(groups);
        if (!groups || !groups.data || !groups.data.listGroups) return;
        if (groups.data.listGroups.items.length == 0) return;
        const groupsMapped = groups.data.listGroups.items.map(g => {
            return {
                key: g.id,
                text: g.name,
                value: g.id
            };
        });
        setGroups(groupsMapped);
    }

    useEffect(() => {
        getGroups();
        
    }, [props.member]);

    const onChange = (e, {value}) => {
        props.onChange(e, {value}, props.member);
    }

    return <Dropdown fluid selection placeholder={groupStrings["en-us"]['group-selection-dropdown']} 
        options={groups ?? []} onChange={onChange}/>;
}

export default GroupMemberMoveDropdown;