import React, {Component} from 'react';
import Unity, { UnityContext } from "react-unity-webgl";

class Episode1 extends Component {
    constructor(props) {
        super(props);
        
        this.unityContext = new UnityContext({
            loaderUrl: "airfilter_prototype/Build/Build.loader.js",
            codeUrl: "airfilter_prototype/Build/Build.wasm",
            frameworkUrl: "airfilter_prototype/Build/Build.framework.js",
            dataUrl: "airfilter_prototype/Build/Build.data",
            streamingAssetsUrl: "airfilter_prototype/StreamingAssets"
        });
    }

    componentDidMount() {
        document.addEventListener('wheel', onScroll, false);
        document.addEventListener('mousemove', onMouse, false);
        var content = document.getElementsByClassName('episode1-unity-canvas');
        function onMouse() { 
            if (!content || content.length == 0) return;
            content[0].style['pointer-events'] = 'auto'; 
        }
        function onScroll() { 
            if (!content || content.length == 0) return;
            content[0].style['pointer-events'] = 'none'; 
        }
    }

    render() {
        //UnityLoader.instantiate("unityContainer", "Build/build.json", {onProgress: UnityProgress});
        
        let unityCanvas = <Unity unityContext={this.unityContext}
            style={{ width: "1200px", height: "675px" }}
            className={"episode1-unity-canvas"} />;
        
        return (
            unityCanvas
        );
    }
}
Episode1.id             = "ep1";
Episode1.title          = "Prototype Air Filter Episode";
Episode1.isAdmin        = false;
Episode1.sender         = "Sent From";
Episode1.emailPreview   = "This is a long preview. It is really really really really really really really really really really really really really really really really really long.";
export default Episode1;