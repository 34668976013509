import { Auth } from 'aws-amplify';
import DatabaseService from '../data/DatabaseService';
import emailTemplates from "../data/users/invitationEmailTemplates";

class EmailService {

    static GetDefaultInvitationEmailTemplate() {
        return emailTemplates.messages["en-us"];
    }

    static async SendIntroEmailToUsers(gameId, toAddresses) {
        let AWS = require("aws-sdk");
        Auth.currentCredentials()
        .then(async credentials => {
            const ses = 
                new AWS.SES({credentials: Auth.essentialCredentials(credentials)});
            let messageText = await this.GetGameIntroEmail(gameId);
            let params = {
                Destination: {
                    ToAddresses: toAddresses
                },
                Message: {
                    Body: {
                        Text: {
                            Data: messageText
                        }
                    },
                    Subject: {
                        Data: emailTemplates.subjects["en-us"]
                    }
                },
                Source: emailTemplates.sources["en-us"],

            };
            console.log(params);
            return ses.sendEmail(params).promise();
        });
    }

    static GetGameIntroEmail(gameId) {
        return new Promise(async (resolve, reject) => {
            let dbService = new DatabaseService();
            let getGame = await dbService.GetGame({id: gameId});
            console.log(getGame);
            if (!getGame.data.getGame.invitationEmailTemplate) {
                resolve(emailTemplates.messages["en-us"]);
            }
            else {
                resolve(getGame.data.getGame.invitationEmailTemplate);
            }
            
        });
    }

    static async SaveIntroEmailTemplate(gameId, introEmail) {
        return new Promise(async (resolve, reject) => {
            let dbService = new DatabaseService();
            let result = await dbService.GetGame({id: gameId});
            console.log(result);
            if (!result || !result.data) {
                reject("Game " + gameId + " not found");
            }
            else {
                var input = {
                    id: gameId,
                    invitationEmailTemplate: introEmail
                }
                console.log(input);
                let update = await dbService.UpdateGame(input);
                console.log(update);
            }
        });
    }

}
export default EmailService;