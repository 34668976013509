import Auth from '@aws-amplify/auth';
import React, { useEffect, useState } from 'react';
import { Menu, Segment, Tab } from 'semantic-ui-react';
import ContentAvailabilityService from '../../data/ContentAvailabilityService';
import MissionDayChat from './MissionDayChat';

const ChatSection = (props) => {
    const [availableEpisodes, setAvailableEpisodes] = useState([]);

    let contentAvailabilityService = new ContentAvailabilityService();

    useEffect(() => {
        Auth.currentAuthenticatedUser().then((user) => {
            console.log(user);
            contentAvailabilityService.getAvailableEpisodesForUser(user.username)
            .then((episodes) => {
                console.log(episodes);
                setAvailableEpisodes(episodes);
            });
        });
    }, []);

    const getPanes = () => {
        if (availableEpisodes.length === 0) return;
        let panes = [] ;
        availableEpisodes.forEach((episode) => {
            panes.push({ menuItem: episode.name, render: () => <Tab.Pane key={episode.name}><MissionDayChat episode={episode} /></Tab.Pane> },);
        });
        return panes;
    };

    return <Segment>
        <Tab panes={getPanes()} />
    </Segment>
}

export default ChatSection;