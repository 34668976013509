import React from 'react';
import { Button, Segment, Header, Modal } from 'semantic-ui-react';

const Tasks = (props) => {
    console.log(props.content);
    let launchTaskButtons = [];
    for (let index = 0; index < props.content.length; index++) {
        const task = props.content[index];
        let taskButton = <Button basic>{task.props.title}</Button>;
        let modal = <Modal
            closeIcon
            size="fullscreen"
            closeOnDimmerClick={false}
            closeOnEscape={true}
            trigger={taskButton}
        >
            <Modal.Content>
                <Modal.Header>{task.props.title}</Modal.Header>
                {task}
            </Modal.Content>
        </Modal> 
        launchTaskButtons.push(modal);
    }
    return (
        <Segment>
            <Header>Select Task</Header>
            {launchTaskButtons.map((b, i) => <div key={i}>{b}</div>)}
        </Segment>
    );
}

export default Tasks;