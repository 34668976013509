import React, { useState } from 'react';
import { Segment, Modal, Image } from 'semantic-ui-react';
import desk from "./assets/PharosDesk.png";
import pulseSequence from "./assets/WavelengthTable.png"
import wavelengthChart from "./assets/WavelengthChart.png"
import ImageMapper from 'react-image-mapper';

const PharosTask = (props) => {

    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState("PulseSequence");

    let map = {
        name: "pharos-map",
        areas: [
            { name: "PulseSequence", shape: "poly", coords: [30,56, 330,68, 600,57, 623,336, 614,520, 331,523, 28,519, 44,312], strokeColor: "transparent", preFillColor: "transparent", fillColor: "transparent"  },
            { name: "WavelengthChart", shape: "poly", coords: [675,230, 1162,230, 1162,523, 920,533, 675,519, 674,336], strokeColor: "transparent", preFillColor: "transparent", fillColor: "transparent"  },
        ]
    }

    let images = {
        "PulseSequence": pulseSequence,
        "WavelengthChart": wavelengthChart
    };

    const onAreaClick = (area) => {
        console.log(area);
        console.log(images[selectedImage]);
        setSelectedImage(area.name);
        setImageModalOpen(true);
    };

    return <div>
        <Modal
            closeIcon
            size="small"
            closeOnDimmerClick={true}
            closeOnEscape={true}
            onClose={() => setImageModalOpen(false)}
            open={imageModalOpen}    
        >
            <Modal.Content>
                <div>
                    {<Image src={images[selectedImage]} />}
                </div>
            </Modal.Content>
        </Modal>
        <Segment textAlign="center">
        <ImageMapper src={desk} map={map} onClick={onAreaClick}/>
        </Segment>
    </div>
}
PharosTask.title = "Pharos Memo";
export default PharosTask;