import React, {useState, useReducer, useEffect} from 'react';
import { Icon, Dropdown, Grid } from 'semantic-ui-react';
import UserService from '../data/users/UserService';


const useFetch = (props) => {
    const [currentRole, setCurrentRole] = useState("Loading role...");

    async function getGroupsForUser() {
        console.log("Getting groups for user");
        return await UserService.ListGroupsForUser(props.user.Username);
    }
    
    
    useEffect(() => {
        getGroupsForUser().then((result) => {
            console.log(result);
            if (result.Groups.length == 0 && !currentRole.value == "") {
                let resultObject = {text: "No roles", value: ""};
                setCurrentRole(resultObject);
                return;    
            };
            let role = result.Groups[0].GroupName;
            let text = "";
            if (role.toLowerCase() == "admin") text = "Teacher"
            else text = role;

            if (currentRole && text == currentRole.text) return;
            console.log("current role " + currentRole.text + " being set to " + text);
            let resultObject = {text: text, value: role};
            setCurrentRole(resultObject);
        });
    }, [currentRole]);
    
    return currentRole; 
};
function ModifyUserRoleOptions(props) {
    //const remoteGroups = useFetch(props);

    const [currentRole, setCurrentRole] = useState("Loading role...");

    const setCurrentRoleCallback = (newRole) => {
        setCurrentRole(newRole);
    };

    async function getGroupsForUser() {
        console.log("Getting groups for user");
        return await UserService.ListGroupsForUser(props.user.Username);
    }
    
    
    useEffect(() => {
        getGroupsForUser().then((result) => {
            if (result.Groups.length == 0 && !currentRole.value == "") {
                let resultObject = {text: "No roles", value: ""};
                setCurrentRole(resultObject);
                return;    
            };
            let role = result.Groups[0].GroupName;
            let text = "";
            if (role.toLowerCase() == "admin") text = "Teacher"
            else text = role;

            if (currentRole && text == currentRole.text) return;
            console.log("current role " + currentRole.text + " being set to " + text);
            let resultObject = {text: text, value: role};
            setCurrentRole(resultObject);
        });
    }, [currentRole]);


    console.log("modify options rendered");

    var roleDropdownOptions = [
        {
            key: "Player",
            text: "Player",
            value: "Player"
        },
        {
            key: "Admin",
            text: "Teacher",
            value: "Admin"
        }
    ];

    return (<Grid>
        <Grid.Row >
            <Grid.Column width="4" >{props.user.Email}</Grid.Column>
            <Grid.Column >{currentRole.text}</Grid.Column>
            <Grid.Column ><Icon name="arrow right"/></Grid.Column>
            <Grid.Column width="2"><Dropdown onChange={(e, data) => props.onChange(e, data, props.user.Username, currentRole, setCurrentRoleCallback)} selection fluid options={roleDropdownOptions}/></Grid.Column>
        </Grid.Row>
    </Grid>);
}

export default ModifyUserRoleOptions;