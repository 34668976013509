/* The purpose of this service is to determine what content is available to a user.
 * This service uses the schedule data in the DB in order to determine this.
 * Schedule data, along with the current user (supplied as input) is used
 * to calculate whether or not a given piece of content (supplied as input)
 * is available.
 */

import testSchedule from './testData/testSchedule.json';
import { updateSchedule, createSchedule } from '../../graphql/mutations';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import SecureLS from 'secure-ls';
import DatabaseService from './DatabaseService';
import UserService from './users/UserService';

class ContentAvailabilityService {

    async createOrUpdateContentSchedule(newSchedule, id, groupId) {
        // Check to see if this exists already
        // currently, just check to see if there is a schedule at all...
        // TODO: be smarter about checking for a schedule by id before potentially making a new one

        let gameId = await UserService.GetAttributeFromCurrentUser("custom:gameId");
        let currentSchedule = await this.getContentSchedule(gameId, groupId);
        console.log(currentSchedule);

        let toReturn = null;

        if (currentSchedule == null || !id){
            // Create a schedule
            
            // generate unique id
            const uuidv1 = require('uuid/v1');
            const id = uuidv1();
            console.log(newSchedule);
            //if no new schedule exists
            if (newSchedule == null) {
                var s = await this.getDefaultContentSchedule();
                newSchedule = s.json;
            }

            let createInput = {
                gameId: gameId,
                groupId: groupId,
                json: newSchedule,
                lastChecked: new Date(0).toString()
            };
            console.log(createInput);
            toReturn = API.graphql(graphqlOperation(createSchedule, {input: createInput})).catch((error) => console.log(error));
        }
        else {
            // Update schedule
            let updateInput = {
                id: id,
                gameId: gameId,
                groupId: groupId,
                json: newSchedule
            };
            console.log(updateInput);
            toReturn = API.graphql(graphqlOperation(updateSchedule, {input: updateInput})).catch((error) => console.log(error));
        }
        this.deleteLocalContentSchedule();
        return toReturn;
    }

    async deleteLocalContentSchedule() {
        let local = await this.getLocalContentSchedule();
        if (!local) return;

        let ls = new SecureLS();
        let item = ls.remove("schedule");
    }

    async getLocalContentSchedule() {
        let ls = new SecureLS();
        let item = ls.get("schedule");
        if (!item) return false;
        let schedule = JSON.parse(item);
        if (!schedule) return false;
        return schedule;
    }

    async getContentSchedule(gameId, groupId) {
        //let localSchedule = await this.getLocalContentSchedule();
        //if (localSchedule) return localSchedule;
        
        console.log("fetching schedule from db");
        let databaseService = new DatabaseService();
        let listSchedulesInput = {
            gameId: {eq: gameId}
        };
        let schedulesForGame = await databaseService.ListSchedules(listSchedulesInput);
        let toReturn = null;
        if (schedulesForGame.data.listSchedules.items)
        {
            toReturn = schedulesForGame.data.listSchedules.items.filter(x => x.groupId == groupId);
            if (toReturn.length > 0) {
                toReturn = toReturn[0];
            }
        }

        if (!toReturn || toReturn.length <= 0) {
            //toReturn = this.getContentSchedule_LOCAL(); //change to fetch default schedule
            toReturn = this.getDefaultContentSchedule();
        }
        return toReturn;
    }

    async getContentSchedule_LOCAL() { //old function that pulls schedule from a backend file
        console.log("getting the local");
        let sched = JSON.parse(JSON.stringify(testSchedule));
        sched.gameId = await UserService.GetAttributeFromCurrentUser("custom:gameId");
        console.log(sched);
        return {
            json: JSON.stringify(sched)
        };
    }

    //grab the default schedule from S3 bucket
    async getDefaultContentSchedule() {
        console.log("Retreiving Schedule JSON from S3")
        var sched = await this.getS3Json("defaultSchedule.json")
        sched.gameId = await UserService.GetAttributeFromCurrentUser("custom:gameId");
        console.log(sched)
        return {
            json: JSON.stringify(sched)
        };
    }
    async getS3Json(filename) {
        //get from: "https://argamplify-content.s3.amazonaws.com/" + filename
        var url = "https://argamplify-content.s3.amazonaws.com/"+filename;

        const response = await fetch(url);

        const responseJson = await response.json();
        return responseJson;

    }

    async getAvailableEpisodesForUser(user) { // "user" is the Cognito user guid
        return new Promise(async (resolve, reject) => {
            let gameId = await UserService.GetAttributeFromCurrentUser("custom:gameId");
            console.log("getAvailableEpisodesForUser gameId: " + gameId);
            let authUser = await Auth.currentAuthenticatedUser();
            let username = authUser.attributes["email"];
            let databaseService = new DatabaseService();
            let dbUserResult = await databaseService.GetUser({name: user, gameId: gameId});
            console.log(dbUserResult);
            let schedule = await this.getContentSchedule(gameId, dbUserResult.data.getUser.groupId);

            if (!schedule) resolve({});

            let parsedSchedule = JSON.parse(schedule.json).schedule;

            // First, grab episodes that the user is allowed to see at all
            var episodesAssigned = parsedSchedule.episodes;

            //if admin, send all the episodes
            if (dbUserResult.data.getUser.role == "Admin") {
                resolve(episodesAssigned);
            }

            // Then, filter out the ones that are not scheduled to be visible yet
            var scheduledEpisodes = episodesAssigned.filter((episode) => {
                var now = new Date();
                var scheduledDate = new Date(episode.scheduledTime);
                return now >= scheduledDate;
            });
            console.log(scheduledEpisodes)
            resolve(scheduledEpisodes);
        });
    }

    async getAvailableDocumentsForUser(user) { // "user" is the Cognito user guid
        return new Promise(async (resolve, reject) => {
            let gameId = await UserService.GetAttributeFromCurrentUser("custom:gameId");
            console.log("getAvailableDocumentsForUser gameId: " + gameId);
            let databaseService = new DatabaseService();
            let dbUserResult = await databaseService.GetUser({name: user, gameId: gameId});
            console.log(dbUserResult);
            var schedule = this.getContentSchedule(gameId, dbUserResult.data.getUser.groupId).then((schedule) => {
                if (!schedule) resolve({});

                let parsedSchedule = JSON.parse(schedule.json).schedule;
                // First, grab episodes that the user is allowed to see at all
                var documentsAssigned = parsedSchedule.documents;

                //if admin, send all the documents
                if (dbUserResult.data.getUser.role == "Admin") {
                    resolve(documentsAssigned);
                }

                // Then, filter out the ones that are not scheduled to be visible yet
                var scheduledDocuments = documentsAssigned.filter((document) => {
                    var now = new Date();
                    var scheduledDate = new Date(document.scheduledTime);
                    return now >= scheduledDate;
                });
                resolve(scheduledDocuments);
            });
        });
    }

    async getAvailableTasksForUser(user) { // "user" is the Cognito user guid
        return new Promise(async (resolve, reject) => {
            let gameId = await UserService.GetAttributeFromCurrentUser("custom:gameId");
            console.log("getAvailableTasksForUser gameId: " + gameId);
            let databaseService = new DatabaseService();
            let dbUserResult = await databaseService.GetUser({name: user, gameId: gameId});
            console.log(dbUserResult);
            var schedule = this.getContentSchedule(gameId, dbUserResult.data.getUser.groupId).then((schedule) => {
                if (!schedule) resolve({});

                let parsedSchedule = JSON.parse(schedule.json).schedule;
                // First, grab episodes that the user is allowed to see at all
                var tasksAssigned = parsedSchedule.tasks;

                //if admin, send all the tasks
                if (dbUserResult.data.getUser.role == "Admin") {
                    resolve(tasksAssigned);
                }

                // Then, filter out the ones that are not scheduled to be visible yet
                var scheduledTasks = tasksAssigned.filter((task) => {
                    var now = new Date();
                    var scheduledDate = new Date(task.scheduledTime);
                    return now >= scheduledDate;
                });
                resolve(scheduledTasks);
            });
        });
    }

}
export default ContentAvailabilityService;