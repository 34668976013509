import React, {useEffect, useState} from 'react';
import { Form, Segment, Header } from 'semantic-ui-react';
import ModifyUserRoleOptions from "./ModifyUserRoleOptions";

function ModifyUserRolesSection(props) {

    var roleModifications = [];

    const userRoleOnChange = (e, data, username, currentRole, callback) => {
        let oldRole = currentRole.value;
        let newRole = data.value;

        if (oldRole == newRole) {
            let roleModIndex = roleModifications.findIndex((rm) => rm.username == username);
            if (roleModIndex > -1) {
                roleModifications.splice(roleModIndex, 1);
            }
        }
        else {
            roleModifications.push({
                username: username,
                oldRole: oldRole,
                newRole: newRole,
                callback: callback
            });
        }
    }

    const modifyUserRoles = (e, data) => {
        props.onSubmit(e, data, roleModifications);
        roleModifications = [];
    }

    return <Segment className="user-management-section no-border">
        <Header as="h2">Change User Roles</Header>
        <Form>
            {props.currentUsers.map((user) => <ModifyUserRoleOptions onChange={userRoleOnChange} key={user.Username + user.Enabled} user={user} />)}
            <Form.Group>
                <Form.Button basic content="Perform Role Changes" onClick={modifyUserRoles} />
            </Form.Group>
        </Form>
    </Segment>
}

export default ModifyUserRolesSection;