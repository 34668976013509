import { Auth } from 'aws-amplify';
import UserService from './users/UserService';

class IdentityService {

    static parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
      };

    static async isInGroup(groupName) {
        const user =  await Auth.currentUserInfo();
        console.log(user);
        console.log(Auth);
        const groups = await UserService.ListGroupsForUser(user.username);
        console.log(groups);
        if (!groups || !groups.Groups || groups.Groups.length == 0) return false;

        return groups.Groups.some(g => g.GroupName == groupName);
    }

    static async isAdmin() {
        return await this.isInGroup("Admin");
    }

    static async isModerator() {
        return await this.isInGroup("Moderator");
    }

    static async isInAnyGroups(groups) {
        for (let group of groups) {
            if (await this.isInGroup(group)) {
                return true;
            }
        }
        return false;
    }

    static isCurrentUserAdmin() {
        return this.isAdmin();
    }

    static getCurrentUsername() {
        return new Promise((resolve, reject) => {
            Auth.currentAuthenticatedUser().then((user) => {
                console.log(user);
                if (user) {
                    resolve(user);
                }
                else {
                    reject("No user logged in")
                }
            });
        });
    }
}

export default IdentityService;