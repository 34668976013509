import React, {Component} from 'react';
import DateTimePicker from 'react-datetime-picker';

class DateTimeContainer extends Component {

    render() {
        return <DateTimePicker onChange={this.props.onChange} disableCalendar={true} disableClock={true} calendarIcon={null} clearIcon={null} value={this.props.value}/>
    }
}

export default DateTimeContainer;