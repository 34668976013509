import React, {useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import { Container } from 'semantic-ui-react';

function Document(props) {
    let { id } = useParams();
    let item = [];

    if (Array.isArray(props.content)) {
        item = props.content.find(x => x.props.id === id.toString());
    }
    else {
        item = <div>Item not found</div>;
    }

    return (
        <div className="document-page">
            <Container className="document-main">
                {item}
            </Container>
        </div>
    );
    
}

export default Document;