import React, {useEffect, useState} from 'react';
import { Form, Input, Button, Segment, Header, Label } from 'semantic-ui-react';
import groupStrings from '../data/groups/groupStrings';
import GroupService from '../data/groups/GroupService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CreateGroupSection(props) {

    const [name, setName] = useState("");
    const [nameError, setNameError] = useState(false);

    const createGroup = async (e, submitData) => {
        if (name === undefined || name === null || name === "") {
            setNameError(true);
            return;
        }
        GroupService.CreateGroup(name)
        .then((result) => {
            toast.success(groupStrings["en-us"]["group-created"]);
        })
        .catch((reason) => {
            toast.error(groupStrings["en-us"]["name-already-exists"]);
        });
    }

    const onNameChange = (e, data) => {
        setName(data.value);
        if (data.value === undefined || data.value === null || data.value === "") {
            setNameError(true);
        }
        else setNameError(false);
    }

    const nameLabel = <Label pointing>
        {groupStrings["en-us"]["please-enter-name"]}
    </Label>;

    return <Segment>
        <Header dividing as="h2">
            Create Group
        </Header>
        <Form onSubmit={createGroup}>
            <Form.Field>
                <Form.Input label="Name" type="text" onChange={onNameChange}/>
                {nameError ? nameLabel : ""}
            </Form.Field>
            <Button basic type="submit">Create</Button>
        </Form>
    </Segment>
}

export default CreateGroupSection;